import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: () => {
        return {
            dark_mode: localStorage.getItem('dark_mode') === "true",
        };
    },
    mutations: {
        updateField,
        QUASAR_DARK_MODE: (state, value) => {
            state.dark_mode = value;
            localStorage.setItem("dark_mode", value);
        },
    },
    getters: {
        getField,
        dark_mode: (state) => {
            return state.dark_mode ?? false;
        }
    },
    actions: {
    }
};
