// "development"
// "production"
let node_env = process.env.NODE_ENV;

const env = {
    environment: node_env,
    get is_development(){ return this.environment==="development" },
    get is_production(){ return !this.is_development && !this.is_staging },
    get is_staging() { return this.environment==="staging" }
}

window.env = env;

// export default env;
