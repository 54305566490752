import { getField, updateField } from 'vuex-map-fields';
import api from "@/libs/api";

export default {
	namespaced: true,
	state: () => {
		return {
            child_route: "",
            RecordSelezionato: [],
            is_loading: false,
            have_errors: false
		};
	},
	mutations: {
		updateField,
	},
	getters: {
        getField,
	},
	actions: {
        async fetchDatiPreventivi({state},anno) {
            state.have_errors = false;
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "portafoglio/getStrutturaPreventivi?AnnoEstrazione="+anno;
            let oJSon = await api.getJSonFromController(url);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.is_loading = true;
            }

            return oJSon.data.result;
        },
        async fetchDatiPratiche({state},anno) {
            state.have_errors = false;
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "portafoglio/getStrutturaPratiche?AnnoEstrazione="+anno;
            let oJSon = await api.getJSonFromController(url);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.is_loading = true;
            }

            return oJSon.data;
        },
	}
};
