import { getField, updateField } from 'vuex-map-fields';
import api from "@/libs/api";

export default {
    namespaced: true,
    state: () => {
        return {
            righe: [],
            colonne: [],
            is_loading: false,
            child_route: "",
            elenco_clienti: [],
            cliente_selezionato: [],
            righe_pratiche_cliente: [],
            colonne_pratiche_cliente: [],
            righe_preventivi_cliente: [],
            colonne_preventivi_cliente: [],
            email: "",
            email_cliente: "",
            isEmailDisabled: true,
            isNavDisabled: true
        };
    },
    mutations: {
        updateField,
    },
    getters: {
        getField,
        getEmailCliente(state) {
            return state.email_cliente;
        },
        getEmailDisabled(state) {
            return state.isEmailDisabled;
        },
        getNavDisabled(state) {
            return state.isNavDisabled;
        }
    },
    actions: {
        setEmailDisabled({ state }, value) {
            state.isEmailDisabled = value;
        },
        setNavDisabled({ state }, value) {
            state.isNavDisabled = value;
        },
        setEmailCliente({ state }, email) {
            state.email_cliente = email;
        },
        async fetchCercaInAnagrafe({ state }, Questionario) {
            //state.have_errors = false;
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "Clienti/CercaPerPreventivo";
            let oJSon = await api.postJSonToController(url, Questionario);

            state.righe = oJSon.data.rows;
            state.colonne = oJSon.data.columns;
            state.is_loading = true;
        },
        async setChildRoute({ state }, child_route) {
            state.child_route = child_route;
        },
        async fetchElencoClienti({ state }, { Cognome, Nome, CodiceFiscale, PartitaIva }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "clienti/cerca?Cognome=" + Cognome + "&Nome=" + Nome + "&CodiceFiscale=" + CodiceFiscale + "&PartitaIva=" + PartitaIva;
            let oJSon = await api.getJSonFromController(url);

            state.elenco_clienti = oJSon.data.elenco;
            state.is_loading = true;

            return oJSon.data.elenco;
        },
        async fetchDatiCliente({ state }, IDCliente) {
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "clienti/" + IDCliente;
            let oJSon = await api.getJSonFromController(url);

            state.is_loading = true;
            return oJSon.data.cliente;
        },
        async fetchElencoClientiPerCellulare({ state }, cellulare) {
            console.log("state:", state);

            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "clienti/cercaPerCellulare?Cellulare=" + cellulare;
            let oJSon = await api.getJSonFromController(url);

            state.elenco_clienti = oJSon.data.elenco;
            state.is_loading = true;

            return oJSon.data.elenco;
        },
        async fetchElencoPraticheCliente({ state }, IDCliente) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "pratiche/getElencoPraticheCliente?idcliente=" + IDCliente;
            let oJSon = await api.getJSonFromController(url);

            state.righe_pratiche_cliente = oJSon.data.rows;
            state.colonne_pratiche_cliente = oJSon.data.columns;
            state.is_loading = true;
            return oJSon.data.elenco;
        },
        async fetchElencoPreventiviCliente({ state }, IDCliente) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "pratiche/getElencoPreventiviCliente?idcliente=" + IDCliente;
            let oJSon = await api.getJSonFromController(url);
            state.righe_preventivi_cliente = oJSon.data.rows;
            state.colonne_preventivi_cliente = oJSon.data.columns;
            state.is_loading = true;

            return oJSon.data.elenco;
        },
        async fetchAggiornaEmailCliente({ state }, { idcliente, email, idpreventivo }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "Clienti/AggiornaEmailCliente";
            let json = {
                idcliente: idcliente,
                email: email,
                idpreventivo: idpreventivo
            }
            await api.postJSonToController(url, json);

            state.is_loading = true;
        }
    }
};
