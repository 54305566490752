import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import store from "@/store";
import auth from "@/libs/auth";

import BoxInformativoRoutes from "./routes/boxInformativo";
import PiattarfomaRamiRoutes from "./routes/piattarfomaRami";
import ErrorRoutes from './routes/errors';
import DevRoutes from './routes/dev';

const routes = [
    ...DevRoutes,
    ...ErrorRoutes,
    ...BoxInformativoRoutes,
    ...PiattarfomaRamiRoutes,
];

const router = new VueRouter({
	mode: 'history',
	routes
});

router.beforeEach(async (to, from, next) => {
    if(to.matched.some(route => route.meta.onlyForDev)) {
        let not_found_route = {name: "NotFound404"};

        if (window.env.is_production) {
            next(not_found_route);
            return;
        }

    }

    if( ! to.matched.some(route => route.meta.requiresAuth)) {
        next();
        return;
    }

    // se non sei autenticato, autenticati
    if ( ! store.getters.is_authenticated) {
        await auth.authenticate();
    }

    // se ancora non sei autenticato, errore
    if ( ! store.getters.is_authenticated) {
        let unauthorized_route = {name: "Unauthorized401"};

        // TODO - Rimetti il blocco IF quando il programma dovrà andare in produzione
        if ((window.env.is_development) ||
            (window.env.is_staging)) {
            unauthorized_route = {name: "DevelopHome"};
        }

        next(unauthorized_route);
        return;
    }

    if(to.name === "PiattaformaRami") {
        next({name: "Preventivi"});
        return;
    }

    next();
});


export default router;
