import '@/libs/env';

import Vue from 'vue';
// import * as Sentry from "@sentry/browser";
// import * as Integrations from "@sentry/integrations";
// import * as Sentry from "@sentry/vue";

import App from './App.vue';

import '@/libs/quasar';

import VueRouter from 'vue-router';
Vue.use(VueRouter);

import Vuex from 'vuex';
Vue.use(Vuex);

Vue.config.productionTip = false;

/*Sentry.init({
    Vue,
    dsn: "https://0f8fbcc712f14a1e84a1a55d2a64c3ad@sentry.next.aby.it/5",
    debug: window.env.is_development,
    environment: window.env.environment,
    // integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    tracesSampleRate: 1.0, // 1.0 = 100%
});*/

import store from './store';
import router from './router';
new Vue({
    store,
    router,
    render: (h) => h(App)
}).$mount('#app');
