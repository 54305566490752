import { getField, updateField } from 'vuex-map-fields';
import api from '@/libs/api';

export default {
    namespaced: true,
    state: () => {
        return {

        };
    },
    mutations: {
        updateField,
    },
    getters: {
        getField,
    },
    actions: {
        async setChildRoute({ state }, child_route) {
            state.child_route = child_route;
        },
        async fetchElencoCompagnie({ state }, ValueAsLabel) {
            let url = process.env.VUE_APP_API_URL + "catalogo-prodotti/elencoCompagnie?ValueAsLabel=" + ValueAsLabel;
            let elenco = await api.getJSonFromController(url);
            console.log("state:", state);
            return elenco.data.compagnie;
        },

        async fetchElencoCompagnieDaCatalogo({ state }, ValueAsLabel) {
            let url = process.env.VUE_APP_API_URL + "catalogo-prodotti/elencoCompagnieByIdSettore?prodotto_id=&ValueAsLabel=" + ValueAsLabel;
            let elenco = await api.getJSonFromController(url);
            let compagnie = elenco.data.compagnie;
            let result = [];

            console.log("state:", state);

            var compagnie_mappate = compagnie.map(compagnia => {
                return {
                    is_visible_if: "",
                    label: compagnia.nome_breve.replaceAll("_", " "),
                    value: compagnia.nome_breve
                };
            });

            var len = compagnie_mappate.length;
            for (var i = 0; i < len; i++) {
                var compagnia = compagnie_mappate[i];
                if (!result.find(c => c.label === compagnia.label)) result.push(compagnia);
            }

            // Gia che ci sono lo ordino pure
            return result.sort((a, b) => {
                if (a.value > b.value) {
                    return 1;
                }
                if (a.value < b.value) {
                    return -1;
                }
                return 0;
            });

        },


        async fetchCatalogoProdottiByIDSettore({ state }, { prodotto_id, ValueAsLabel }) {
            // TODO - Il nome di questo end-point e` proprio sbagliato - CORREGGI il prima possibile
            // TODO ================================================================================
            let url = process.env.VUE_APP_API_URL + "catalogo-prodotti/elencoCompagnieByIDSettore?prodotto_id=" + prodotto_id + "&ValueAsLabel=" + ValueAsLabel;
            let elenco = await api.getJSonFromController(url);
            console.log("state:", state);
            return elenco.data.compagnie;
        },
        async fetchElencoTariffe() {
            let url = process.env.VUE_APP_API_URL + "catalogo-prodotti/elencoTariffe";
            let elenco = await api.getJSonFromController(url);

            //console.log("tariffe:", elenco.data.tariffe);

            return elenco.data.tariffe;
        },
        async fetchElencoFornitori() {
            let url = process.env.VUE_APP_API_URL + "catalogo-prodotti/elencoFornitori";
            let elenco = await api.getJSonFromController(url);

            //console.log("Elenco fornitori:", elenco);
            return elenco.data.fornitori;
        },
        async fetchElencoEmittenti() {
            let url = process.env.VUE_APP_API_URL + "catalogo-prodotti/elencoEmittenti";
            let elenco = await api.getJSonFromController(url);

            //console.log("Elenco emittenti:", elenco);
            return elenco.data.emittenti;
        },

        async fetchIDSettore({ state }, prodotto_id) {
            console.log("state:", state);
            let url = process.env.VUE_APP_API_URL + "catalogo-prodotti/IdSettore?prodotto_id=" + prodotto_id;
            let elenco = await api.getJSonFromController(url);

            console.log("elenco:", parseInt(elenco.data));

            return elenco.data;
        }
    }
};
