export default {
    rcp_tecnico: {
        id_prodotto: "rcp_tecnico",
        is_valid: false,
        domande: {
            data_inizio_attivita: {
                name: "data_inizio_attivita",
                type: "date",
                label: "Data inizio attivita`",
                placeholder: "",
                validation: "required|date",
                is_disabled: false,
                is_visible: true,
                is_visible_if: "",
                label_prepend: "",
                label_append: "",
                options: [],
                value: "",
            },
            data_iscrizione_albo: {
                name: "data_iscrizione_albo",
                type: "date",
                label: "Data Iscrizione all`Albo",
                placeholder: "",
                validation: "required|date",
                is_disabled: false,
                is_visible: true,
                is_visible_if: "",
                label_prepend: "",
                label_append: "",
                options: [],
                value: "",
            },
            numero_iscrizione_albo: {
                name: "numero_iscrizione_albo",
                type: "text",
                label: "Numero di iscrizione Albo",
                placeholder: "",
                validation: "required",
                is_disabled: false,
                is_visible: true,
                is_visible_if: "",
                label_prepend: "",
                label_append: "",
                options: [],
                value: "",
            },
            sezione_albo: {
                name: "sezione_albo",
                type: "text",
                label: "Sezione Albo",
                placeholder: "",
                validation: "required",
                is_disabled: false,
                is_visible: true,
                is_visible_if: "",
                label_prepend: "",
                label_append: "",
                options: [],
                value: "",
            },
            data_ultimo_aggiornamento_prof: {
                name: "data_ultimo_aggiornamento_prof",
                type: "date",
                label: "Data ultimo aggiornamento professionale",
                placeholder: "",
                validation: "required|date",
                is_disabled: false,
                is_visible: true,
                is_visible_if: "",
                label_prepend: "",
                label_append: "",
                options: [],
                value: "",
            },
            numero_crediti_conseguiti: {
                name: "numero_crediti_conseguiti",
                type: "number",
                label: "Numero di crediti conseguiti",
                placeholder: "",
                validation: "required",
                is_disabled: false,
                is_visible: true,
                is_visible_if: "",
                label_prepend: "",
                label_append: "",
                options: [],
                value: "",
            },
            chiude_attivita: {
                name: "chiude_attivita",
                type: "radio",
                label: "Hai intenzione di chiudere l`attivita nei prossimi tre anni?",
                placeholder: "",
                validation: "required",
                is_disabled: false,
                is_visible: true,
                is_visible_if: "",
                label_prepend: "",
                label_append: "",
                options: [
                    { value: "si", label: "Si", is_visible_if: "" },
                    { value: "no", label: "No", is_visible_if: "" },
                ],
                value: "no",
            },
            vuole_postuma: {
                name: "vuole_postuma",
                type: "radio",
                label: "Hai intenzione di acquistare la garanzia Postuma?",
                placeholder: "",
                validation: "required",
                is_disabled: false,
                is_visible: true,
                is_visible_if: "chiude_attivita:si",
                label_prepend: "",
                label_append: "",
                options: [
                    { value: "si", label: "Si", is_visible_if: "" },
                    { value: "no", label: "No", is_visible_if: "" },
                ],
                value: "no",
            },
            rifiuto_compagnia: {
                name: "rifiuto_compagnia",
                type: "radio",
                label: "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?",
                placeholder: "",
                validation: "required",
                is_disabled: false,
                is_visible: true,
                is_visible_if: "",
                label_prepend: "",
                label_append: "",
                options: [
                    { value: "si", label: "Si", is_visible_if: "" },
                    { value: "no", label: "No", is_visible_if: "" },
                ],
                value: "no",
            },
            motivo_rifiuto_compagnia: {
                name: "motivo_rifiuto_compagnia",
                type: "textarea",
                label: "Breve descrizione dei motivi del rifiuto",
                placeholder: "",
                validation: "required",
                is_disabled: false,
                is_visible: true,
                is_visible_if: "rifiuto_compagnia:si",
                label_prepend: "",
                label_append: "",
                options: [],
                value: "",
            },
            funzioni_pubbliche: [
                {
                    descrizione: {
                        name: "descrizione1",
                        type: "text",
                        label: "Funzione 1",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                    data_dal: {
                        name: "data_dal1",
                        type: "date",
                        label: "Attività ricoperta dal giorno",
                        placeholder: "",
                        validation: "required|date",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                },
                {
                    descrizione: {
                        name: "descrizione2",
                        type: "text",
                        label: "Funzione 2",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                    data_dal: {
                        name: "data_dal2",
                        type: "date",
                        label: "Attività ricoperta dal giorno",
                        placeholder: "",
                        validation: "required|date",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                },
                {
                    descrizione: {
                        name: "descrizione3",
                        type: "text",
                        label: "Funzione 3",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                    data_dal: {
                        name: "data_dal3",
                        type: "date",
                        label: "Attività ricoperta dal giorno",
                        placeholder: "",
                        validation: "required|date",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                },
            ],
            fatturato: {
                fatturato_corrente: {
                    name: "fatturato_corrente",
                    type: "number",
                    label: "Stima del Fatturato anno Corrente",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "Euro",
                    options: [],
                    value: "0",
                },
                fatturato_due_anni_fa: {
                    name: "fatturato_due_anni_fa",
                    type: "number",
                    label: "Fatturato Consuntivo due anni fa",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "Euro",
                    options: [],
                    value: "0",
                },
                fatturato_tre_anni_fa: {
                    name: "fatturato_tre_anni_fa",
                    type: "number",
                    label: "Fatturato consuntivo tre anni fa",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "Euro",
                    options: [],
                    value: "0",
                },
            },
            fatturato_estero: {
                perc_fatturato_ue: {
                    name: "perc_fatturato_ue",
                    type: "percentuale",
                    label: "Percentuale fatturato UE (escluso Italia)",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "",
                    options: [],
                    value: "0",
                },
                perc_fatturato_non_ue: {
                    name: "perc_fatturato_non_ue",
                    type: "percentuale",
                    label: "Percentuale fatturato extra UE",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "",
                    options: [],
                    value: "0",
                },
                perc_fatturato_usa_canada: {
                    name: "perc_fatturato_usa_canada",
                    type: "percentuale",
                    label: "Percentuale fatturato USA e Canada",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "",
                    options: [],
                    value: "0",
                },
            },
            distribuzione_fatturato: [
                {
                    label: "Amministratore di stabili",
                    perc_anno_precedente: {
                        name: "perc_anno_precedente",
                        type: "number",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "0",
                    },
                    perc_anno_corrente: {
                        name: "perc_anno_corrente",
                        type: "number",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "0",
                    },
                },
            ],
            dati_sindaco: [
                {
                    societa: {
                        name: "societa1",
                        type: "text",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                    capitale_sociale: {
                        name: "capitale_sociale1",
                        type: "number",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "Euro",
                        options: [],
                        value: "0",
                    },
                    fatturato: {
                        name: "fatturato1",
                        type: "number",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "Euro",
                        options: [],
                        value: "0",
                    },
                    settore_attivita: {
                        name: "settore_attivita1",
                        type: "text",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                    ruolo: {
                        name: "ruolo1",
                        type: "text",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                    quotate_in_borsa: {
                        name: "quotate_in_borsa1",
                        type: "checkbox",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: false,
                    },
                    data_inizio_incarico: {
                        name: "data_inizio_incarico1",
                        type: "date",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                },
                {
                    societa: {
                        name: "societa2",
                        type: "text",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                    capitale_sociale: {
                        name: "capitale_sociale2",
                        type: "number",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "Euro",
                        options: [],
                        value: "0",
                    },
                    fatturato: {
                        name: "fatturato2",
                        type: "number",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "Euro",
                        options: [],
                        value: "0",
                    },
                    settore_attivita: {
                        name: "settore_attivita2",
                        type: "text",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                    ruolo: {
                        name: "ruolo2",
                        type: "text",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                    quotate_in_borsa: {
                        name: "quotate_in_borsa2",
                        type: "checkbox",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: false,
                    },
                    data_inizio_incarico: {
                        name: "data_inizio_incarico2",
                        type: "date",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                },
                {
                    societa: {
                        name: "societa3",
                        type: "text",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                    capitale_sociale: {
                        name: "capitale_sociale3",
                        type: "number",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "Euro",
                        options: [],
                        value: "0",
                    },
                    fatturato: {
                        name: "fatturato3",
                        type: "number",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "Euro",
                        options: [],
                        value: "0",
                    },
                    settore_attivita: {
                        name: "settore_attivita3",
                        type: "text",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                    ruolo: {
                        name: "ruolo3",
                        type: "text",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                    quotate_in_borsa: {
                        name: "quotate_in_borsa3",
                        type: "checkbox",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: false,
                    },
                    data_inizio_incarico: {
                        name: "data_inizio_incarico3",
                        type: "date",
                        label: "",
                        placeholder: "",
                        validation: "required",
                        is_disabled: false,
                        is_visible: true,
                        is_visible_if: "",
                        label_prepend: "",
                        label_append: "",
                        options: [],
                        value: "",
                    },
                },
            ],
            dati_consigliere_amministrazione: {
                name: "dati_consigliere_amministrazione",
                type: "group_checkbox",
                label: "L'Assicurato è o è stato in passato sindaco revisore o consigliere di amministrazione di società o enti:",
                placeholder: "",
                validation: "required",
                is_disabled: false,
                is_visible: true,
                is_visible_if: "",
                label_prepend: "",
                label_append: "",
                options: [
                    {
                        value: "amministrazione_controllata",
                        label: "che sono stati oggetto di 'Amministrazione controllata'",
                        is_visible_if: "",
                    },
                    {
                        value: "dichiarati_falliti",
                        label: "che sono stati dichiarati in stato di fallimento",
                        is_visible_if: "",
                    },
                    {
                        value: "concordato_preventivo",
                        label: "che sono stati sottoposti a concordato preventivo",
                        is_visible_if: "",
                    },
                    {
                        value: "amministrazione_straordinaria",
                        label: "che sono stati oggetto di amministrazione straordinaria",
                        is_visible_if:
                            "che sono stati oggetto di amministrazione straordinaria",
                    },
                    {
                        value: "liquidazione_coatta",
                        label: "che sono stati dichiarati in stato di liquidazione coatta amministrativa",
                        is_visible_if: "",
                    },
                    {
                        value: "procedimento_sopra",
                        label: "per le quali e` in corso un procedimento per la dichiarazione di una delle situazioni sopra",
                        is_visible_if: "",
                    },
                ],
                value: [],
            },
            cliente_50_percento: {
                nominativo: {
                    name: "nominativo",
                    type: "text",
                    label: "Nominativo Cliente",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "",
                    options: [],
                    value: "",
                },
                settore_attivita: {
                    name: "settore_attivita",
                    type: "text",
                    label: "Settore Attività",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "",
                    options: [],
                    value: "",
                },
                altro: {
                    name: "altro",
                    type: "text",
                    label: "Altre Informazioni",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "",
                    options: [],
                    value: "",
                },
                perc_fatturato: {
                    name: "perc_fatturato",
                    type: "percentuale",
                    label: "Percentuale del fatturato specifico sul totale dichiarato",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "",
                    options: [],
                    value: "0",
                },
            },
            richieste_risarcimento: false,
            copertura_assicurativa_precedente: {
                nome_assicuratore: {
                    name: "nome_assicuratore",
                    type: "text",
                    label: "Nome Assicuratore",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "",
                    options: [],
                    value: "",
                },
                massimale: {
                    name: "massimale",
                    type: "number",
                    label: "Massimale assicurato",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "",
                    options: [],
                    value: "",
                },
                franchigie_scoperti: {
                    name: "franchigie_scoperti",
                    type: "text",
                    label: "Franchigie o Scoperti",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "",
                    options: [],
                    value: "",
                },
                premio_lordo: {
                    name: "premio_lordo",
                    type: "number",
                    label: "Premio lordo di polizza",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "Euro",
                    options: [],
                    value: "0",
                },
                retroattivita: {
                    name: "retroattivita",
                    type: "text",
                    label: "Retroattivita`",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "",
                    options: [],
                    value: "",
                },
            },
            sinistri: {
                data_richiesta_risarcimento: {
                    name: "data_richiesta_risarcimento",
                    type: "date",
                    label: "Data ricezione della richiesta o della contestazione",
                    placeholder: "",
                    validation: "required|date",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "",
                    options: [],
                    value: "",
                },
                periodo_contestazione: {
                    name: "periodo_contestazione",
                    type: "text",
                    label: "Periodo in cui si è verificato l'errore professionale",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "",
                    options: [],
                    value: "",
                },
                valore_richiesta_danni: {
                    name: "valore_richiesta_danni",
                    type: "number",
                    label: "Valore della richiesta danni del terzo danneggiato",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "Euro",
                    options: [],
                    value: "",
                },
                valore_sinistro_pagato: {
                    name: "valore_sinistro_pagato",
                    type: "number",
                    label: "Valore del sinistro pagato",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "Euro",
                    options: [],
                    value: "",
                },
                pagamento_con_transazione: {
                    name: "pagamento_con_transazione",
                    type: "radio",
                    label: "Il pagamento del danno e` avvenuto a seguito di transazione?",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "",
                    options: [
                        { value: "si", label: "Si", is_visible_if: "" },
                        { value: "no", label: "No", is_visible_if: "" },
                    ],
                    value: "no",
                },
                pagamento_con_sentenza_civile: {
                    name: "pagamento_con_sentenza_civile",
                    type: "radio",
                    label: "Il pagamento del danno e` avvenuto a seguito di una sentenza civile?",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "",
                    options: [
                        { value: "si", label: "Si", is_visible_if: "" },
                        { value: "no", label: "No", is_visible_if: "" },
                    ],
                    value: "no",
                },
                descrizione_fatti: {
                    name: "descrizione_fatti",
                    type: "textarea",
                    label: "Breve descrizione dei fatti",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "",
                    options: [],
                    value: "",
                },
                descrizione_possibili_richieste_danni: {
                    name: "descrizione_possibili_richieste_danni",
                    type: "textarea",
                    label: "Breve descrizione dei fatti",
                    placeholder: "",
                    validation: "required",
                    is_disabled: false,
                    is_visible: true,
                    is_visible_if: "",
                    label_prepend: "",
                    label_append: "",
                    options: [],
                    value: "",
                },
            },
        },
    },
};
