import api from "@/libs/api";
import apiGeo from "@/libs/api-geo";
// import mock_questionari from "@/components/QuestionariAssuntivi/Professionisti/mock_questionario_avvocato.js";
import commonLib from '@/libs/commonLib';
import { getField, updateField } from "vuex-map-fields";

export default {
    namespaced: true,
    state: () => {
        return {
            step: 1,
            righe: [],
            colonne: [],
            vuole_analisi_necessita: false,
            is_loading: true,

            preventivo: {
                create_at: "",
                analisi_necessita: [],
                analisi_rischio: [],
                prodotti: {
                    proposte: [],
                },
            },

            IndiceProdotto: 0,
            IndiceMinimo: 0,
            IndiceMassimo: 0,
            // pulsanti_preventivi_diretti: [],
            have_errors: false,
            dati_cliente: [
                {
                    domande: [],
                },
            ],
            id_unita_operativa: "",
            stato_pratica: "",
            unita_operativa: {},
            persona_operativa: {},
            intermediario_capo_gruppo: {},
            IDCliente: "",
        };
    },
    mutations: {
        updateField,
        clear(state) {
            state.IDCliente = "";
        },
        updateDomande(state, preventivo) {
            state.preventivo = preventivo;
        },
        updateProdotti(state, prodotti) {
            state.preventivo.prodotti = prodotti;
        },
        updateCampo(state, { key, val }) {
            state[key] = val;
        },
        setRichiestaAnalisiNecessita(state) {
            state.setRichiestaAnalisiNecessita = true;
        },
        resetRichiestaAnalisiNecessita(state) {
            state.setRichiestaAnalisiNecessita = false;
            state.preventivo.analisi_necessita = [];
        },
        incrementaIndiceProdotto(state) {
            state.IndiceProdotto += 1;
        },
        decrementaIndiceProdotto(state) {
            state.IndiceProdotto -= 1;
            if (state.IndiceProdotto < 0) {
                //state.IndiceProdotto = 0;
                this.resetIndiceProdotto;
            }
        },
        resetIndiceProdotto(state) {
            // cerca il primo prodotto selezionato
            for (
                let indice = 0;
                indice < state.preventivo.prodotti.length;
                indice++
            ) {
                if (state.preventivo.prodotti[indice].is_checked) {
                    state.IndiceProdotto = indice;
                    break;
                }

                if (state.IndiceProdotto < 0) state.IndiceProdotto = 0;
            }

            // cerca il totale prodotti selezionati
            var Totale = 0;
            for (
                let indice = 0;
                indice < state.preventivo.prodotti.length;
                indice++
            ) {
                if (state.preventivo.prodotti[indice].is_checked) {
                    Totale++;
                }

                // resetta tutte le proposte precedentemente selezionate
                for (
                    let prop = 0;
                    prop < state.preventivo.prodotti[indice].proposte.length;
                    prop++
                ) {
                    state.preventivo.prodotti[indice].proposte[
                        prop
                    ].is_checked = false;
                }
            }

            state.IndiceMinimo = state.IndiceProdotto;
            state.IndiceMassimo = state.IndiceMinimo + Totale;
        },
        updateDatiPreventivo(state, formPreventivo) {
            state.preventivo = formPreventivo.preventivo;
            state.IDCliente = formPreventivo.idCliente;
            state.step = 6;
        },
        setFormPreventivoDaBackend(state, formPreventivo) {
            //console.log("setformPreventivoDaBackend:", formPreventivo);
            Object.keys(formPreventivo).forEach(key => {
                //console.log("key:", key, formPreventivo[key]);
                if (!commonLib.isEmpty(state[key]))
                    state[key] = formPreventivo[key];
            });
        },
        resetStep(state) {
            state.step = 1;
        },
    },
    getters: {
        getField,

        formPreve: (state) => {
            return state;
        }
    },
    actions: {
        async resetFormPreventivo({ commit }) {

            let form_preventivo_vuoto = {
                step: 1,
                righe: [],
                colonne: [],
                vuole_analisi_necessita: false,
                is_loading: true,

                preventivo: {
                    create_at: "",
                    analisi_necessita: [],
                    analisi_rischio: [],
                    prodotti: {
                        proposte: [],
                    },
                },

                IndiceProdotto: 0,
                IndiceMinimo: 0,
                IndiceMassimo: 0,
                // pulsanti_preventivi_diretti: [],
                have_errors: false,
                dati_cliente: [
                    {
                        domande: [],
                    },
                ],
                id_unita_operativa: "",
                stato_pratica: "",
                unita_operativa: {},
                persona_operativa: {},
                intermediario_capo_gruppo: {},
                IDCliente: "",
            };

            commit("setFormPreventivoDaBackend", form_preventivo_vuoto);
            //console.log("Ho spianato il formPreventivo");
        },
        async resetStatoIniziale({ dispatch }) {
            await dispatch("resetQuestionariAssuntivi");
        },
        //    async resetQuestionariAssuntivi({ state }) {
        //        state.questionari_assuntivi = {
        //            rcp_tecnico: {},
        //            multirischio_casa: {}
        //        };
        //    },
        async resetDatiCliente({ state }) {
            state.dati_cliente = [
                {
                    domande: [],
                },
            ];
        },
        async resetPreventivo({ state }) {
            state.dati_cliente = {
                create_at: "",
                prodotti: {
                    proposte: [],
                },
            };
        },
        reset_is_loading({ state }) {
            state.is_loading = true;
        },

        async sendEmailToClient({ state }, id_preventivo) {
            let url =
                process.env.VUE_APP_API_URL +
                "formPreventivo/email-box-informativo?id_preventivo=" +
                id_preventivo;

            //console.log("id_preventivo:",id_preventivo);
            console.log("url:", url, state);

            await api.getJSonFromController(url);
        },
        // ======================================================================
        async fetchDatiProspect({ state }) {
            state.is_loading = false;
            let url =
                process.env.VUE_APP_API_URL + "formPreventivo/getDatiProspect";
            let oJSon = await api.getJSonFromController(url);
            state.preventivo.dati_prospect = oJSon.data;

            state.is_loading = true;
        },
        // ======================================================================
        async fetchDomandeQuestionario({ state, rootState }) {
            state.is_loading = false;

            //if (commonLib.isEmpty(state.preventivo.analisi_necessita)) {
            let url =
                process.env.VUE_APP_API_URL +
                "formPreventivo/analisiNecessita?area=" +
                rootState.area;

            //console.log("url:", url);

            let oJSon = await api.getJSonFromController(url);

            if (oJSon.status !== 200) {
                this.have_errors = true;
            } else {
                state.preventivo = oJSon.data.preventivo;
            }
            //}

            state.is_loading = true;
        },
        // ======================================================================
        async fetchPulsantiPreventivi({ state, rootState }) {
            state.is_loading = false;
            state.have_errors = false;

            let url =
                process.env.VUE_APP_API_URL +
                "formPreventivo/prodotti?area=" +
                rootState.area;
            let oJSon = await api.getJSonFromController(url);

            if (oJSon.status !== 200) {
                state.have_errors = true;
            } else {
                state.pulsanti_preventivi_diretti = oJSon.data.lista_preventivi;

            }

            state.is_loading = true;
        },
        // ======================================================================
        async fetchFormPreventivo({ state }, id_preventivo) {

            let url = process.env.VUE_APP_API_URL +
                "formPreventivo/getDatiPreventivo?id=" + id_preventivo;
            let response = await api.getJSonFromController(url);

            // console.log("store.formPreventivo.fetchFormPreventivo response", response);

            if (response.status !== 200) {
                console.error("store.formPreventivo.fetchFormPreventivo response", response.error);
                state.have_errors = true;
                return {};
            }

            console.log("STORE: formPreventivo:", response.data.formPreventivo);

            state.preventivo = response.data.formPreventivo.preventivo;
            state.cellulare_bloccato = response.data.formPreventivo.cellulare_bloccato;

            return response.data.formPreventivo
        },
        // ======================================================================
        async fetchDatiPreventivo({ state, dispatch }, id_preventivo) {
            state.have_errors = false;
            state.is_loading = false;
            state.righe = [];

            var form_preventivo = await dispatch("fetchFormPreventivo", id_preventivo);
            // commit("setFormPreventivoDaBackend", form_preventivo);

            Object.keys(form_preventivo).forEach(key => {
                if ((!commonLib.isEmpty(state[key])) && (!commonLib.isEmpty(form_preventivo[key])))
                    state[key] = form_preventivo[key];
            });

            state.IDCliente = form_preventivo.idCliente;
            state.preventivo.isQuotazioneRinnovo = form_preventivo.preventivo.isQuotazioneRinnovo;
            state.preventivo.id = id_preventivo;
            state.is_loading = true;

            return {};
        },
        async fetchElencoPreventivi({ state }, area) {
            state.is_loading = false;
            state.have_errors = false;

            let url =
                process.env.VUE_APP_API_URL +
                "formPreventivo/ListaPreventivi?area=" +
                area;
            let oJSon = await api.getJSonFromController(url);

            //console.log("formPreventivo/fetchElencoPreventivi.JSON:",oJSon.data);

            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.righe = oJSon.data.rows;
                state.colonne = oJSon.data.columns;
            }

            state.is_loading = true;

            return oJSon;
        },
        async fetchQuestionarioDatiCliente({ state, rootState }) {
            state.have_errors = false;
            state.is_loading = false;
            let url =
                process.env.VUE_APP_API_URL +
                "formPreventivo/datiCliente?tipo_domande=dati_cliente&area=" +
                rootState.area;
            let oJSon = await api.getJSonFromController(url);

            if (oJSon.status !== 200) {
                state.have_errors = true;
            } else {
                state.dati_cliente = oJSon.data.dati_cliente;
            }

            state.is_loading = true;

            return oJSon.data;
        },
        async fetchUrlDocumentoPreContrattuale({ state }, id_pratica) {
            state.is_checked = !state.is_checked;
            state.is_checked = !state.is_checked;
            state.is_loading = false;
            let url =
                process.env.VUE_APP_API_URL +
                "generaDocumenti/documentoPreContrattuale?id_pratica=" +
                id_pratica;
            let path = await api.getJSonFromController(url);
            state.is_loading = true;

            return path;
        },
        async fetchCancellaPreventivo({ state }, id_preventivo) {
            state.is_loading = false;
            let url =
                process.env.VUE_APP_API_URL +
                "formPreventivo/cancellaPreventivo?id=" +
                id_preventivo;
            await api.postJSonToController(url);
            state.is_loading = true;
        },

        async aggiornaFormPreventivo({ state }, { guidPreventivo, formPreventivo }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "formPreventivo/aggiornaFormPreventivo";
            let dati = {
                guid_preventivo: guidPreventivo,
                form_preventivo: formPreventivo
            };

            await api.postJSonToController(url, dati);

            state.is_loading = true;
        },

        // =============================================================================

        async getGeoProvinceForFormField() {
            let province_api = await apiGeo.fetchProvince();
            let province = province_api
                .map((prov) => {
                    return {
                        is_visible_if: "",
                        label: prov.nome.toUpperCase(),
                        value: prov.sigla,
                    };
                })
                .sort((provinca1, provincia2) => {
                    if (
                        provinca1.label.toUpperCase() <
                        provincia2.label.toUpperCase()
                    )
                        return -1;
                    if (
                        provinca1.label.toUpperCase() >
                        provincia2.label.toUpperCase()
                    )
                        return 1;
                    return 0;
                });

            return province;
        },
        async getGeoComuniForProvincia({ state }, provincia) {
            console.log(state);
            //console.log("getGeoComuniForformField:",provincia);
            let comuni_api = await apiGeo.fetchComuni(provincia);
            let comuni = comuni_api
                .map((comune) => {
                    return {
                        is_visible_if: "",
                        label: comune.nome,
                        value: comune.nome,
                    };
                })
                .sort((comune1, comune2) => {
                    if (
                        comune1.label.toUpperCase() <
                        comune2.label.toUpperCase()
                    )
                        return -1;
                    if (
                        comune1.label.toUpperCase() >
                        comune2.label.toUpperCase()
                    )
                        return 1;
                    return 0;
                });

            return comuni;
        },
        async getGeoCapForComune({ state }, comune) {
            console.log(state);
            let comuni_api = await apiGeo.fetchComuniForName(comune);

            let cap = comuni_api[0].cap
                .map((c) => {
                    return {
                        is_visible_if: "",
                        label: c,
                        value: c,
                    };
                })
                .sort((cap1, cap2) => {
                    if (cap1.label.toUpperCase() < cap2.label.toUpperCase())
                        return -1;
                    if (cap1.label.toUpperCase() > cap2.label.toUpperCase())
                        return 1;
                    return 0;
                });

            return cap;
        },
        async getGeoComuniForName({ state }, comune) {
            console.log("getGeoComuniForName.state:", state);
            let comuni_api = await apiGeo.fetchComuniForName(comune);

            return comuni_api;
        },
        async getGeoStatiForFormField() {
            let stati_api = await apiGeo.fetchStati();
            let stati = stati_api
                .map((stato) => {
                    return {
                        is_visible_if: "",
                        label: stato.nome,
                        value: stato.nome.toUpperCase(),
                    };
                })
                .sort((stato1, stato2) => {
                    if (stato1.value === "ITALIA") return -1;
                    if (stato2.value === "ITALIA") return 1;

                    if (stato1.label.toUpperCase() < stato2.label.toUpperCase())
                        return -1;
                    if (stato1.label.toUpperCase() > stato2.label.toUpperCase())
                        return 1;
                    return 0;
                });

            return stati;
        },
        async getGeoTuttiComuni() {
            let comuni_api = await apiGeo.fetchTuttiComuni();
            let comuni = comuni_api
                .map((comune) => {
                    return {
                        is_visible_if: "",
                        label: comune.nome,
                        value: comune.nome.toUpperCase(),
                    };
                })
                .sort((comune1, comune2) => {
                    if (
                        comune1.label.toUpperCase() <
                        comune2.label.toUpperCase()
                    )
                        return -1;
                    if (
                        comune1.label.toUpperCase() >
                        comune2.label.toUpperCase()
                    )
                        return 1;
                    return 0;
                });

            return comuni;
        },
        async getProvinciaFromComune({ state }, comune) {
            console.log("comune richiesto:", comune, state);
            return await apiGeo.provinciaFromComune(comune);
        },
        async popolaProvinceEStati({ state, dispatch }) {
            let stati = await dispatch("getGeoStatiForFormField");
            let comuni = await dispatch("getGeoTuttiComuni");

            let dom_comune_residenza = state.dati_cliente[0].domande.find(
                (dom) => dom.name === "comune_residenza"
            );
            dom_comune_residenza.options = comuni;

            let dom_comune_corrispondenza = state.dati_cliente[0].domande.find(
                (dom) => dom.name === "comune_corrispondenza"
            );
            dom_comune_corrispondenza.options = comuni;

            let dom_comune_nascita = state.dati_cliente[0].domande.find(
                (dom) => dom.name === "comune_nascita"
            );
            dom_comune_nascita.options = comuni;

            let dom_stato_nascita = state.dati_cliente[0].domande.find(
                (dom) => dom.name === "stato_nascita"
            );
            dom_stato_nascita.options = stati;
        },
        async setComuniCorrispondenza({ state }, comuni) {
            state.dati_cliente[0].comuni_corrispondenza = comuni;
        },
        async setComuniResidenza({ state }, comuni) {
            state.dati_cliente[0].comuni_residenza = comuni;
        },
        async setComuniNascita({ state }, comuni) {
            state.dati_cliente[0].comuni_nascita = comuni;
        },
        async isEmailBoxInformativoSender({ state }, id_preventivo) {
            let url =
                process.env.VUE_APP_API_URL +
                "formPreventivo/email-boxinformativo-sender?id_preventivo=" +
                id_preventivo;
            let result = await api.getJSonFromController(url);

            if (result.status !== 200) {
                state.have_errors = true;
            } else {
                state.is_loading = true;
                state.have_errors = false;
            }

            return result.data;
        },
        async fetchElencoMovimentiPreventivo({ state }, id_preventivo) {
            let url =
                process.env.VUE_APP_API_URL +
                "formPreventivo/registro-movimenti-preventivo?id_preventivo=" +
                id_preventivo;
            let result = await api.getJSonFromController(url);

            if (result.status !== 200) {
                state.have_errors = true;
            } else {
                state.is_loading = true;
                state.have_errors = false;
            }

            state.is_loading = true;

            return result.data;
        },
        async fetchDatiEconomiciPreventivo({ state }, { guidPreventivo, prodotto_id_tariffa, premio_tariffa, provvigioni_tariffa }) {
            state.is_loading = false;

            let dati = {
                guid_preventivo: guidPreventivo,
                prodotto_id: prodotto_id_tariffa,
                premio: premio_tariffa,
                provvigioni: provvigioni_tariffa
            }

            //console.log("dati:", dati);

            let url =
                process.env.VUE_APP_API_URL + "formPreventivo/DatiEconomiciPreventivo";

            let oJSon = await api.postJSonToController(url, dati);

            //console.log("oJSon:", oJSon);

            state.is_loading = true;

            return oJSon.data;
        },

        async fetchDatiUfficioDaPreventivo({ state }, GuidPratica) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "formPreventivo/getDatiUfficioDaPreventivo?guid_pratica=" + GuidPratica;
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;

            return oJSon.data.dati_ufficio;
        }
    },
};
