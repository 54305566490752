import commonLib from "@/libs/commonLib";
import store from "@/store";

let auth = {
    async authenticate() {
        if(this.urlContieneJwt()) {
            let jwt = await this.getJwtFromUrl();
            // await this.putJwtInStore(jwt);
            localStorage.setItem("jwt", jwt);
        }

        await store.dispatch("authenticate");
    },

    logout() {
        localStorage.setItem("jwt", "");
    },

    urlContieneJwt() {
        return !commonLib.isEmpty(commonLib.getJwtFromUrl());
    },

    async getJwtFromUrl() {
        return await commonLib.getJwtFromUrl();
    },

    getUser() {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return {
            id_persona_operativa: jwt.id_unita_operativa,
            nome_persona_operativa: jwt.operatore,
            id_unita_operativa: jwt.id_unita_operativa,
            nome_unita_operativa: jwt.nome_unita_operativa,
            nome_intermediario_capogruppo: jwt.nome_intermediario_capogruppo,
            ruolo: jwt.ruolo
        }
    },
}

export default auth;
