//import axios from 'axios';
import Vue from "vue";
import QQButton from "@/components/QQButton.vue";
import validation from "@/libs/validation";

// controlla se un valore è undefined
let isUndefined = function (variable) {
    return typeof variable === "undefined";
};

// controlla se un valore è null
let isNull = function (variable) {
    if (isUndefined(variable)) {
        return false;
    }

    return variable === null;
};

// controlla se un valore è null, undefined o vuoto
let isNullOrEmpty = function (variable) {
    return isNull(variable) || isEmpty(variable);
};

let isNotNull = function (variable) {
    return !isNull(variable);
};

let isObject = function (variable) {
    return typeof variable === "object";
};

let isAnnoBisestile = function (Anno) {
    Anno = parseInt(Anno);
    if (Anno % 400 === 0) return true;

    if (Anno % 4 === 0 && Anno % 100 !== 0) return true;

    return false;
};

let isDate = function (variable) {
    if (variable === "") return false;

    if (variable.length != 10) return false;

    let elements = variable.split("/");
    if (elements.length != 3) return false;

    let GiorniPerMese = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Il controllo vale per anni bisestili
    if (elements[0] > GiorniPerMese[parseInt(elements[1])]) return false;

    // Controlla anni non bisestili
    if (parseInt(elements[1]) == 2 && !isAnnoBisestile(elements[2])) {
        if (elements[0] > 28) return false;
    }

    return true;
};

// controlla se un valore è null, undefined o vuoto
let isEmpty = function (variable) {
    if (isUndefined(variable)) {
        return true;
    }

    if (isNull(variable)) {
        return true;
    }

    //TODO: logica singole tipologie

    if (typeof variable === "string") {
        return variable === "";
    }

    if (typeof variable === "number") {
        return isNaN(variable);
    }

    if (typeof variable === "object") {
        return Object.keys(variable).length === 0;

        //TODO
        // da vedere, queste cose tornano "object" per typeof,
        // la length delle keys è 0 ma non sono vuoti
        // Object.keys(new Date()).length => 0
        // Object.keys( /regex/).length => 0
        // Object.keys(Boolean(true)).length => 0
        // Object.keys(Number(1)).length => 0
        // Object.keys(String('abc')).length => 0
        // vedi https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof
    }

    return false;
};

// verifica che una variabile sia una funzione eseguibile
let isCallable = function (variable) {
    if (isNull(variable)) {
        return false;
    }

    return typeof variable === "function";
};

/* ==================================================================
    La funzione legge i cookie e recupera la variabile passata
    come parametro.
    PARAM cname (string) - Identifica il nome del campo da estrarre
                           dal cookie
    RESTITUISCE: (string) - Il valore della variabile registrata
   ================================================================== */
let getCookie = function (cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

/* ================================================
    La funzione legge la variabile TOKEN dal cookie
    e la invia al BackEnd per un controllo.
    RESTITUISCE: (boolean) - Se il token è valido,
                             restituisce TRUE.
   ================================================ */
let getQueryParamFromUrl = function (param_name) {
    let url = new URL(location.href);
    let param_value = url.searchParams.get(param_name);

    if (isEmpty(param_value)) {
        return "";
    }

    return param_value;
};

let getJwtFromUrl = function () {
    return getQueryParamFromUrl("jwt");
};

let estraiJsonPayloadDaJwt = function (jwt) {
    var base64_payload = jwt.split(".")[1];
    var string_payload = atob(base64_payload);
    return JSON.parse(string_payload);
};

let JWTtoJSon = function (token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
};

let getUrlCommand = function (command) {
    return new URL(location.href).searchParams.get(command);
};

/* ================================================================
    Inserisce un pulsante all'interno del DOM puntato da $DOMobj.
    PARAMS $DOMobj (object) - Puntatore al DOM del container dove
                              inserire il pulsante
    PARAMS Icon (string) - Identifica il tipo di icona da inserire
                           nel pulsante
    PARAMS Label (string) - Identifica la descrittiva da inserire
                            nel pulsante
    PARAMS Color (string) - Identifica il colore del pulsante
    Restituisce: Niente
   ================================================================ */
let insertButtonWithDOM = function ($DOMobj, Icon, Label, Color) {
    var ComponentClass = Vue.extend(QQButton);

    var instance = new ComponentClass({
        propsData: {
            icon: Icon,
            label: Label,
            color: Color,
        },
    });

    instance.$mount(); // pass nothing

    $DOMobj.appendChild(instance.$el);
};

/*let isDomandaVisible = function (Domande, SingolaDomanda) {
    console.log("SingolaDo0manda.is_visible_if:", SingolaDomanda.is_visible_if);

    if (!this.isEmpty(SingolaDomanda.is_visible_if)) {
        // Recupera i nome del campo di riferimento
        let NomeCampo = SingolaDomanda.is_visible_if.split(":")[0];
        let ValoreCampo = SingolaDomanda.is_visible_if.split(":")[1];

        //console.log("nome,valore:",NomeCampo,ValoreCampo);

        // Cerca il campo con l'attributo NAME uguale a quello cercato
        let Campo = Domande.filter((domanda) => {
            return (
                domanda.name == NomeCampo &&
                this.isDomandaVisible(Domande, domanda)
            );
        });

        // Cerca il valore impostato sul campo appena trovato
        if (Campo.length > 0) {
            let value = Campo[0].value;

            return ValoreCampo.includes(value);
        }

        // TODO - Bisognerebbe cercare la domanda nell'analisi delle necessita


        return false;
    }
    return true;
}; */

let isDomandaVisible = async function (Domande, SingolaDomanda) {

    if (!this.isEmpty(SingolaDomanda.is_visible_if)) {

        if (!SingolaDomanda.is_visible) return false;

        // Verifica se si tratta di richieste multiple
        var richieste = SingolaDomanda.is_visible_if.split(";");
        var is_visible = true;

        for (var i = 0; i < richieste.length; i++) {
            var richiesta = richieste[i];

            // Recupera i nome del campo di riferimento
            let NomeCampo = richiesta.split(":")[0];
            let ValoreCampo = richiesta.split(":")[1];

            // Cerca il campo con l'attributo NAME uguale a quello cercato
            let Campo = Domande.filter((domanda) => {
                return (
                    (domanda.name == NomeCampo) &&
                    (this.isDomandaVisible(Domande, domanda))
                );
                //is_visible = is_visible && domanda.name == NomeCampo && this.isDomandaVisible(Domande, domanda);
                //return domanda.name == NomeCampo;
            });

            // Cerca il valore impostato sul campo appena trovato
            if (Campo.length > 0) {
                let value = Campo[0].value;

                if (!this.isEmpty(value))
                    is_visible = is_visible && ValoreCampo.includes(value);
                else
                    is_visible = false;
            }

        }

        return is_visible;
    }

    return SingolaDomanda.is_visible;
}

let controllaSeQuestionarioCompilato = async function (questionario) {
    let tot = questionario.length;
    let questionario_valido = true;

    for (let i = 0; i < tot; i++) {
        // Controlla solo le domande che risultano visibili

        if (
            questionario[i].is_visible &&
            questionario[i].type !== "group_checkbox" &&
            questionario[i].type !== "checkbox" &&
            questionario[i].type !== "button" &&
            questionario[i].type !== "separator" &&
            questionario[i].type !== "title"
        ) {
            let visible = await this.isDomandaVisible(questionario, questionario[i]);

            if (!visible) continue;

            // controlla le regole di validazione
            let regola = questionario[i].validation;
            let valore = questionario[i].value;
            let attributi = regola.split("|");

            if (visible && regola !== "") {
                let { Msg, esito } = validation.verificaRegole(
                    attributi,
                    valore
                );
                //console.log("Messaggio:", Msg);
                if (Msg != "") Msg = "";

                //if (esito) return false;
                //if (this.isEmpty(questionario[i].value)) return false;

                if (esito) questionario_valido = false;
                if (this.isEmpty(questionario[i].value)) questionario_valido = false;

                questionario_valido = questionario_valido && true;

                //return true;
            }

            questionario_valido = questionario_valido && true;

            //if (this.isEmpty(questionario[i].value)) return false;

            //return true;
        }
    }

    return questionario_valido;
};

let ottieni_consonanti = function (nominativo) {
    return nominativo.replace(/[^BCDFGHJKLMNPQRSTVWXYZ]/gi, "");
};

let ottieni_vocali = function (nominativo) {
    return nominativo.replace(/[^AEIOU]/gi, "");
};

let controllaCodiceFiscale = function (CodiceFiscale, cognome, nome) {
    var pattern =
        /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;

    CodiceFiscale = CodiceFiscale.toUpperCase();

    // utilizzo il metodo search per verificare che il valore inserito nel campo
    // di input rispetti la stringa di verifica (pattern)
    if (CodiceFiscale.search(pattern) == -1) {
        return false;
    }

    // TODO - analizza nome e cognome sulle prime 6 cifre del codice fiscale
    var codice_cognome = this.ottieni_consonanti(cognome);
    codice_cognome += this.ottieni_vocali(cognome);
    codice_cognome += "XXX";
    codice_cognome = codice_cognome.substr(0, 3);
    codice_cognome = codice_cognome.toUpperCase();

    var codice_nome = this.ottieni_consonanti(nome);
    if (codice_nome.length >= 4) {
        codice_nome =
            codice_nome.charAt(0) +
            codice_nome.charAt(2) +
            codice_nome.charAt(3);
    } else {
        codice_nome += this.ottieni_vocali(nome);
        codice_nome += "XXX";
        codice_nome = codice_nome.substr(0, 3);
    }
    codice_nome = codice_nome.toUpperCase();

    let codice_verifica = codice_cognome + codice_nome;
    let codice_cf = CodiceFiscale.substring(0, 6).toUpperCase();

    if (codice_cf !== codice_verifica) {
        return false;
    }

    let set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
    let s = 0;
    for (let i = 1; i <= 13; i += 2)
        s += setpari.indexOf(
            set2.charAt(set1.indexOf(CodiceFiscale.charAt(i)))
        );

    for (let i = 0; i <= 14; i += 2)
        s += setdisp.indexOf(
            set2.charAt(set1.indexOf(CodiceFiscale.charAt(i)))
        );

    // Codice di controllo errato
    if (s % 26 != CodiceFiscale.charCodeAt(15) - "A".charCodeAt(0))
        return false;

    // ...in caso contrario stampo un avviso di successo!
    return true;
};

let castDateToIso = function (dateValue) {
    if (isEmpty(dateValue)) {
        var data = new Date(Date.now());
        var anno = data.getFullYear() + "";
        var mese = data.getMonth() + "";
        var giorno = data.getDay() + "";
        //console.log("data:",data);
        return anno + "-" + mese.padStart(2, '0') + "-" + giorno.padStart(2, '0');
    }

    if (dateValue.includes("/")) {
        var element = dateValue.split("/");
        return element[2] + "-" + element[1].padStart(2, '0') + "-" + element[0].padStart(2, '0');
    }

    return dateValue;
};

const sleep = ms => new Promise(r => setTimeout(r, ms));

export default {
    isUndefined,
    isNull,
    isNullOrEmpty,
    isNotNull,
    isEmpty,
    isObject,
    isCallable,
    isAnnoBisestile,
    isDate,
    insertButtonWithDOM,
    getCookie,
    getUrlCommand,
    getJwtFromUrl,
    getQueryParamFromUrl,
    JWTtoJSon,
    estraiJsonPayloadDaJwt,
    controllaSeQuestionarioCompilato,
    isDomandaVisible,
    controllaCodiceFiscale,
    ottieni_consonanti,
    ottieni_vocali,
    sleep,
    castDateToIso
};
