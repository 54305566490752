import { getField, updateField } from 'vuex-map-fields';
import api from "@/libs/api";

export default {
    namespaced: true,
    state: () => {
        return {
            child_route: "",
            righe: [],
            colonne: [],
            is_loading: true,
            formPratica: [],
            RecordSelezionato: [],
            dati_cliente: {}
        };
    },
    mutations: {
        updateField,
    },
    getters: {
        getField,
    },
    actions: {
        async setChildRoute({ state }, child_route) {
            state.child_route = child_route;
        },
        async fetchPratiche({ state }) {
            state.have_errors = false;
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "pratiche/ListaPratiche";
            let oJSon = await api.getJSonFromController(url);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.righe = oJSon.data.rows;
                state.colonne = oJSon.data.columns;
                state.is_loading = true;
            }
        },
        async fetchDatiPratica({ state }, { numero_pratica, token }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "pratiche/getDatiPratica?id=" + numero_pratica;

            //console.log("url:",url);

            if (token !== "") url += "&token=" + token;
            let oJSon = await api.getJSonFromController(url);

            state.formPratica = oJSon.data.formPreventivo;

            //console.log("decorrenza:",state.formPratica.preventivo.decorrenza);

            state.is_loading = true;

            return oJSon.data.pratica;
        },
        async fetchNotePratica({ state }, numero_pratica) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "pratiche/getNotePratica?id=" + numero_pratica;
            let oJSon = await api.getJSonFromController(url);

            //console.log("oJSon:",oJSon);

            //state.formPratica = oJSon.data.formPreventivo;
            state.is_loading = true;

            return oJSon.data.note;
        },
        async fetchCambiaStatoPratica({ state }, { numero_pratica, stato_pratica, note }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "pratiche/cambiaStatoPratica?NumeroPratica=" + numero_pratica + "&stato=" + stato_pratica + "&note=" + note;
            await api.getJSonFromController(url);
            state.is_loading = true;
        },
        async fetchDatiClientePratica({ state }, numero_pratica) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "pratiche/getDatiClientePratica?id_Pratica=" + numero_pratica;
            var oJSon = await api.getJSonFromController(url);

            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.dati_cliente = oJSon.data.cliente;
            }

            //console.log("dati_cliente da fetch:",oJSon.data.cliente);

            state.is_loading = true;

            return oJSon.data.cliente;
        },
        /*    async fetchCancellaPratica({state},numero_pratica) {
                state.is_loading = false;
                let url = process.env.VUE_APP_API_URL + "pratiche/cancellaPratica?NumeroPratica="+numero_pratica;
                await api.postJSonToController(url);
                state.is_loading = true;
            },*/
        async setAnnullaPratica({ state }, { numero_pratica, note }) {
            state.is_loading = false;
            //let url = process.env.VUE_APP_API_URL + "pratiche/cancellaPratica?NumeroPratica="+numero_pratica;
            //let url = process.env.VUE_APP_API_URL + "pratiche/annulla?NumeroPratica="+numero_pratica+"&note="+note;
            let url = process.env.VUE_APP_API_URL + "pratiche/annulla";
            let dati = { NumeroPratica: numero_pratica, MotivoAnnullo: note };

            await api.postJSonToController(url, dati);

            state.is_loading = true;
        },
        async setPraticaInLavorazione({ state }, numero_pratica) {
            state.is_loading = false;
            //let url = process.env.VUE_APP_API_URL + "pratiche/cancellaPratica?NumeroPratica="+numero_pratica;
            let url = process.env.VUE_APP_API_URL + "pratiche/confermaRichiestaEmissione?NumeroPratica=" + numero_pratica;
            await api.postJSonToController(url);

            state.is_loading = true;
        },
        async setProblemaSuPratica({ state }, { numero_pratica, descrizione, toBackOffice }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "pratiche/segnalaProblema";
            await api.postJSonToController(url, { "numero_pratica": numero_pratica, "note": descrizione, "toBackOffice": toBackOffice });

            state.is_loading = true;
        },
        async fetchProblemiPratica({ state }, numero_pratica) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "pratiche/elencoProblemi?numero_pratica=" + numero_pratica;


            let oJSon = await api.getJSonFromController(url);

            //console.log("oJSon:",oJSon);

            state.is_loading = true;

            return oJSon.data;
        },
        async RisolviProblemaPratica({ state }, { IDPratica, IDProblema }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "pratiche/" + IDPratica + "/risolviProblema/" + IDProblema;
            await api.getJSonFromController(url);
            state.is_loading = true;
        },
        async fetchDatiEconomiciPratica({ state }, id_pratica) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "pratiche/getDatiEconomici?id_pratica=" + id_pratica;
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;

            return oJSon.data.datiEconomici;
        },
        async fetchDatiEconomiciBoxContrattuale({ state }, id_pratica) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "box-informativo/getDatiEconomici?guid_pratica=" + id_pratica;
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;

            return oJSon.data.dati_economici;
        },
        async fetchPremiQuietanzeAttive({ state }, NumeroPratica) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "pratiche/getPremiQuietanzeAttive?NumeroPratica=" + NumeroPratica;
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;

            return oJSon.data;
        },
        async fetchElencoPagamentiPratica({ state }, NumeroPratica) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "pratiche/getPagamentiPratica?NumeroPratica=" + NumeroPratica;
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;

            return oJSon.data;
        },
        async fetchElencoContrattiPratica({ state }, NumeroPratica) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "pratiche/getContratti?guid_pratica=" + NumeroPratica;
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;

            return oJSon.data;
        },
        async fetchDatiUfficioDaPratica({ state }, GuidPratica) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "pratiche/getDatiUfficioDaPratica?guid_pratica=" + GuidPratica;
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;

            return oJSon.data.dati_ufficio;
        },

        async fetchElencoContrattiQuietanze({ state }, guidPratica) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "pratiche/getElencoContrattiQuietanze?guid_pratica=" + guidPratica;
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;

            return oJSon.data.elenco;
        }
    }

};

