import { getField, updateField } from 'vuex-map-fields';
import api from '@/libs/api';

export default {
    namespaced: true,
    state: () => {
        return {
            elenco_documenti: {},
            elenco_docs: new Array(),

            documenti_pratica: {
                url_documento_precontrattuale: "",
                url_dichiarazioni_contraente: "",
                documenti_allegati: [
                    {
                        tariffa: "",
                        descrizione_documento: "",
                        is_checked: ""
                    }
                ]
            },
            isDocumentiPraticaScaricati: false,
            is_loading: false
        }
    },
    mutations: {
        updateField,
    },
    getters: {
        getField,
    },
    actions: {
        clearElencoDocumentiPratica({ state }) {
            state.isDocumentiPraticaScaricati = false;
            state.documenti_pratica.url_documento_precontrattuale = "";
            state.documenti_pratica.url_dichiarazioni_contraente = "";
            state.documenti_pratica.documenti_allegati = [];

            //console.log("Puntatore scarico documenti resettato");
        },
        async fetchUrlDocumentoPreContrattuale({ state }, id_preventivo) {
            state.is_checked = !state.is_checked;
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "generaDocumenti/documentoPreContrattuale?id_preventivo=" + id_preventivo;
            let path = await api.getJSonFromController(url);
            state.is_loading = true;

            return path.data.url;
        },
        async fetchUrlRicevutaPagamento({ state }, { NumeroPratica, create_at }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "generaDocumenti/ricevutaPagamento?numero_pratica=" + NumeroPratica + "&create_at=" + create_at;
            let path = await api.getJSonFromController(url);
            state.is_loading = true;

            return path.data.url;
        },
        async fetchUrlRicevutaPagamentoQuietanza({ state }, { NumeroPratica, IDQuietanza, create_at }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "generaDocumenti/ricevutaPagamentoQuietanza?numero_pratica=" + NumeroPratica + "&create_at=" + create_at + "&idquietanza=" + IDQuietanza;
            let path = await api.getJSonFromController(url);
            state.is_loading = true;

            return path.data.url;
        },
        async fetchUrlDichiarazioniContraente({ state }, id_preventivo) {
            state.is_checked = !state.is_checked;
            //state.is_checked = !state.is_checked;
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "generaDocumenti/dichiarazioniCliente?id_preventivo=" + id_preventivo;
            let path = await api.getJSonFromController(url);
            state.is_loading = true;

            return path.data.url;
        },
        async fetchUrlReclami({ state }, id_preventivo) {
            state.is_checked = !state.is_checked;
            //state.is_checked = !state.is_checked;
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "generaDocumenti/reclami?id_preventivo=" + id_preventivo;
            let path = await api.getJSonFromController(url);
            state.is_loading = true;

            return path.data.url;
        },
        async fetchUrlElencoCompagnie({ state }, id_preventivo) {
            state.is_checked = !state.is_checked;
            //state.is_checked = !state.is_checked;
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "generaDocumenti/compagnie?id_preventivo=" + id_preventivo;
            let path = await api.getJSonFromController(url);
            state.is_loading = true;

            return path.data.url;
        },
        async fetchUrlPOGAby({ state }, id_preventivo) {
            state.is_checked = !state.is_checked;
            //state.is_checked = !state.is_checked;
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "generaDocumenti/pog_aby?id_preventivo=" + id_preventivo;
            let path = await api.getJSonFromController(url);
            state.is_loading = true;

            return path.data.url;
        },
        async fetchUrlRaccomandazionePersonalizzata({ state }, id_preventivo) {
            state.is_checked = !state.is_checked;
            //state.is_checked = !state.is_checked;
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "generaDocumenti/raccomandazione_personalizzata?id_preventivo=" + id_preventivo;
            let path = await api.getJSonFromController(url);
            state.is_loading = true;

            return path.data.url;
        },

        async fetchUrlPrivacy({ state }, id_preventivo) {
            state.is_checked = !state.is_checked;
            //state.is_checked = !state.is_checked;
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "generaDocumenti/privacy?id_preventivo=" + id_preventivo;
            let path = await api.getJSonFromController(url);
            state.is_loading = true;

            return path.data.url;
        },

        async fetchUrlFilieraDistribuitiva({ state }, id_preventivo) {
            state.is_checked = !state.is_checked;
            //state.is_checked = !state.is_checked;
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "generaDocumenti/filiera_distributiva?id_preventivo=" + id_preventivo;
            let path = await api.getJSonFromController(url);
            state.is_loading = true;

            return path.data.url;
        },

        async fetchUrlMercatoRiferimento({ state }, id_preventivo) {
            state.is_checked = !state.is_checked;
            //state.is_checked = !state.is_checked;
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "generaDocumenti/mercato_riferimento?id_preventivo=" + id_preventivo;
            let path = await api.getJSonFromController(url);
            state.is_loading = true;

            return path.data.url;
        },

        async fetchUrlDichiarazioniCoerenza({ state }, id_preventivo) {
            state.is_checked = !state.is_checked;
            //state.is_checked = !state.is_checked;
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "generaDocumenti/dichiarazioni_coerenza?id_preventivo=" + id_preventivo;
            let path = await api.getJSonFromController(url);
            state.is_loading = true;

            return path.data.url;
        },

        async fetchUrlElencoPreventivi({ state }, id_preventivo) {
            state.is_checked = !state.is_checked;
            //state.is_checked = !state.is_checked;
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "generaDocumenti/preventivi?id_preventivo=" + id_preventivo;
            let path = await api.getJSonFromController(url);
            state.is_loading = true;

            return path.data.url;
        },

        async fetchDownloadUrl({ rootState }, Filename) {
            rootState.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "documenti/getDownloadUrl?filename=" + Filename;
            let path = await api.getJSonFromController(url);

            rootState.is_loading = true;

            return path.data; // Per ora
        },
        async fetchUploadUrl({ rootState }, { IDPreventivo, Filename, InfoDocumento }) {
            rootState.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "documenti/getUploadUrlPreventivi?NumeroPreventivo=" + IDPreventivo + "&Filename=" + Filename;
            //console.log("url:",url,InfoDocumento);
            let path = await api.postJSonToController(url, InfoDocumento);

            rootState.is_loading = true;

            return path.data.result;
        },

        async fetchUploadUrlSimpli({ rootState }, { guid_pratica, Filename, IDQuietanza }) {
            rootState.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "documenti/getUploadUrlSimpli?GuidPratica=" + guid_pratica + "&Filename=" + Filename + "&IDQuietanza=" + IDQuietanza;

            console.log("url store:", url);

            let path = await api.getJSonFromController(url);

            rootState.is_loading = true;

            return path.data;
        },

        async fetchUploadUrlContratti({ rootState }, { NumeroPratica, Filename, IndiceContratto }) {
            rootState.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "documenti/getUploadUrlContratti?NumeroPratica=" + NumeroPratica + "&Filename=" + Filename + "&IndiceContratto=" + IndiceContratto;
            let path = await api.getJSonFromController(url);

            rootState.is_loading = true;

            return path.data.result.data;
        },
        async fetchUploadUrlQuietanza({ rootState }, { NumeroPratica, Filename, IDQuietanza }) {
            rootState.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "documenti/getUploadUrlQuietanza?NumeroPratica=" + NumeroPratica + "&Filename=" + Filename + "&IDQuietanza=" + IDQuietanza;
            let path = await api.getJSonFromController(url);

            rootState.is_loading = true;

            return path.data.result.data;
        },
        async fetchDownloadUrlContratti({ rootState }, NumeroPratica) {
            rootState.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "documenti/getDownloadUrlContratti?NumeroPratica=" + NumeroPratica;
            let path = await api.getJSonFromController(url);
            rootState.is_loading = true;

            return path.data.result;
        },
        /* async fetchElencoDocumenti({ state, rootState }) {
            // recupera il prodotto_id dal nodo preventivo
            var prodotto_id = "";
            var tariffe = {
                "tariffe": []
            };
            // recupera la lista delle tariffe selezionate

            var prodotti_attivi = rootState.formPreventivo.preventivo.prodotti.filter(prodotto => {
                if (prodotto.is_checked) {
                    prodotto_id = prodotto.id;
                }
                return prodotto.is_checked;
            });
            // scansiona i prodotti attivi per cercare le proposte selezionate
            prodotti_attivi.forEach(prodotto => {
                prodotto.proposte.forEach(proposta => {
                    if (proposta.is_checked) {
                        tariffe.tariffe.push(proposta.tariffa);
                    }
                })
            })

            let url = process.env.VUE_APP_API_URL + "documenti/getElencoDocumenti?prodotto_id=" + prodotto_id;
            let elenco = await api.postJSonToController(url, tariffe);

            console.log("elenco documenti:", elenco);

            state.elenco_documenti = elenco.data.elencoDocumenti;

            return elenco.data.elencoDocumenti;
        }, */

        async fetchElencoDocumenti({ state, rootState }, isPersonaFisica = true) {
            // recupera il prodotto_id dal nodo preventivo
            var prodotti = {
                "prodotto_id": [],
                is_persona_fisica: isPersonaFisica
            };
            // recupera la lista delle tariffe selezionate

            // var prodotti_attivi =
            rootState.formPreventivo.preventivo.prodotti.filter(prodotto => {
                if (prodotto.is_checked) {
                    prodotti.prodotto_id.push(prodotto.id);
                }
                return prodotto.is_checked;
            });
            // scansiona i prodotti attivi per cercare le proposte selezionate
            /*    prodotti_attivi.forEach(prodotto => {
                    prodotto.proposte.forEach(proposta => {
                        if (proposta.is_checked) {
                            tariffe.tariffe.push(proposta.tariffa);
                        }
                    })
                }) */

            let url = process.env.VUE_APP_API_URL + "documenti/getElencoDocumentiExt";
            let elenco = await api.postJSonToController(url, prodotti);

            //console.log("elenco documenti:", elenco);
            //console.log("prodotti:", prodotti);

            state.elenco_documenti = elenco.data.elencoDocumenti;

            return elenco.data.elencoDocumenti;
        },

        async fetchElencoDocumentiPratica({ state }, elenco_prodotto_id) {
            var prodotti = {
                "prodotto_id": elenco_prodotto_id
            };

            let url = process.env.VUE_APP_API_URL + "documenti/getElencoDocumentiExt";
            let elenco = await api.postJSonToController(url, prodotti);
            state.elenco_documenti = elenco.data.elencoDocumenti;

            return elenco.data.elencoDocumenti;
        },

        /*    async fetchElencoDocumentiPratica({ state, rootState }) {
                // recupera il prodotto_id dal nodo preventivo
                var prodotto_id = "";
                var tariffe = {
                    "tariffe": []
                };
                // recupera la lista delle tariffe selezionate

                var prodotti_attivi = rootState.gestionePratiche.formPratica.preventivo.prodotti.filter(prodotto => {
                    if (prodotto.is_checked) {
                        prodotto_id = prodotto.id;
                    }
                    return prodotto.is_checked;
                });
                // scansiona i prodotti attivi per cercare le proposte selezionate
                prodotti_attivi.forEach(prodotto => {
                    prodotto.proposte.forEach(proposta => {
                        if (proposta.is_checked) {
                            tariffe.tariffe.push(proposta.tariffa);
                        }
                    })
                })

                let url = process.env.VUE_APP_API_URL + "documenti/getElencoDocumenti?prodotto_id=" + prodotto_id;
                let elenco = await api.postJSonToController(url, tariffe);

                state.elenco_documenti = elenco.data.elencoDocumenti;
                state.is_loading = true;

                return elenco.data.elencoDocumenti;
            }, */
        async fetchElencoDocumentiDaPreventivo({ state }, numero_preventivo) {
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "documenti/getElencoDocumentiPreventivo?numero=" + numero_preventivo;
            let elenco = await api.getJSonFromController(url);

            state.elenco_documenti = elenco.data.result;
            state.is_loading = true;

            return elenco;
        },
        async fetchElencoDocumentiTariffa({ state }, { id_tariffa, data_create_at }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "catalogo-prodotti/documentiTariffa?id_tariffa=" + id_tariffa + "&data=" + data_create_at;
            let elenco = await api.getJSonFromController(url);
            state.is_loading = true;

            //console.log("elenco.data:",elenco.data);

            return elenco.data;
        },
        async deleteDocumentoFromDb({ state }, { numero, documento }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_RAMI_URL + "generaDocumenti/cancellaDocumentoDaDb?numero=" + numero + "&tipo=0";
            await api.postJSonToController(url, documento);
            state.is_loading = true;
        },
        fetchNewGUID() {
            let url = process.env.VUE_APP_API_URL + "documenti/getNewGUID";
            return api.getJSonFromController(url);
        },
        async fetchElencoCompletoDocumentiPratica({ state }, { id_pratica, decorrenza }) {
            // id_pratica identifica il GUID della pratica
            console.log(decorrenza);
            state.is_loading = false;
            var url = process.env.VUE_APP_API_URL + "box-informativo/elenco_documenti?id=" + id_pratica;
            var oJSon = await api.getJSonFromController(url);

            state.documenti_pratica = oJSon.data;

            console.log("elenco completo documenti:", state.documenti_pratica);

            return state.documenti_pratica;
        }

        /*    async fetchElencoCompletoDocumentiPratica({ state }, { id_pratica, decorrenza }) {
                // Agisce solo se i documenti non sono stati scaricati prima
                state.is_loading = false;

                //    if (!state.isDocumentiPraticaScaricati) {

                var anno = decorrenza.split("/")[2];
                var mese = decorrenza.split("/")[1].padStart(2, '0');

                // console.log("anno,mese:",anno,mese);

                // Recupera URL documento pre-contrattuale
                var filename = "documenti/preventivi/" + anno + "/" + mese + "/" + id_pratica + "/documentoPreContrattuale.pdf";

                //console.log("filename:",filename);

                let url = process.env.VUE_APP_API_URL + "documenti/getDownloadUrl?filename=" + filename;
                let path = await api.getJSonFromController(url);
                state.documenti_pratica.url_documento_precontrattuale = path.data;

                // Recupera URL dichiarazioni del contraente
                filename = "documenti/preventivi/" + anno + "/" + mese + "/" + id_pratica + "/dichiarazioniCliente.pdf";
                url = process.env.VUE_APP_API_URL + "documenti/getDownloadUrl?filename=" + filename;
                path = await api.getJSonFromController(url);
                state.documenti_pratica.url_dichiarazioni_contraente = path.data;

                // Scarica i documenti allegati
                url = process.env.VUE_APP_API_URL + "documenti/getElencoDocumentiPreventivo?numero=" + id_pratica;
                var elenco = await api.getJSonFromController(url);
                state.documenti_pratica.documenti_allegati = elenco.data.result;

                //console.log("elenco documenti:",state.documenti_pratica.documenti_allegati);

                state.isDocumentiPraticaScaricati = true;
                //    }

                state.is_loading = true;

                return state.documenti_pratica;
            } */
    }
};
