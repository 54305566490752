import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);



import actions from './actions';
import formPreventivo from './modules/formPreventivo';
import gestionePreventivi from './modules/gestionePreventivi';
import gestionePratiche from './modules/gestionePratiche';
import gestioneClienti from './modules/gestioneClienti';
import gestioneDocumenti from './modules/gestioneDocumenti';
import gestioneCatalogoProdotti from './modules/gestioneCatalogoProdotti';
import geoStore from './modules/geoStore';
import gestioneEstrazioni from './modules/gestioneEstrazioni';
import gestioneStatistiche from './modules/gestioneStatistiche';
import gestioneQuotazioni from './modules/gestioneQuotazioni';
import gestioneOTP from './modules/gestioneOTP';
import gestioneFatture from './modules/gestioneFatture';
import gestioneRinnovi from './modules/gestioneRinnovi';
import gestioneSimpli from './modules/gestioneSimpli';
import localStorage from './modules/localStorage';
import commonLib from "@/libs/commonLib";
import { getField, updateField } from 'vuex-map-fields';
//import { createLogger } from 'vuex'

const store = new Vuex.Store({
    //plugins: [createLogger()],
    modules: {
        gestionePratiche,
        gestionePreventivi,
        formPreventivo,
        gestioneClienti,
        gestioneDocumenti,
        gestioneCatalogoProdotti,
        geoStore,
        gestioneEstrazioni,
        gestioneStatistiche,
        gestioneQuotazioni,
        gestioneOTP,
        gestioneFatture,
        gestioneRinnovi,
        gestioneSimpli,
        localStorage
    },

    state: {
        is_authenticated: false,
        jwt: "",

        sezione_visualizzata: "Preventivi",
        is_sezione_visible: true,
        area: '',
        titolo_area: '',
        context: '',
        axios_is_initialized: false,
        is_loading: false,
        has_error: false,
        error: {
            title: "",
            message: "",
            callback: null
        },
        elenco_documenti: {},
        avatar: "@/images/male3.png",
        operatore: "",
        unita_operativa: "",
        registro_movimenti: []
    },

    mutations: {
        setAuthendicated: state => state.is_authenticated = true,
        setUnauthendicated: state => state.is_authenticated = false,
        updateField,
        async setArea(state, area) {
            let AreaReale = area;
            state.area = AreaReale;
            state.titolo_area = 'Area ' + state.area;
        },
        async initAxios(state) {
            state.axios_is_initialized = true;
        },
        async setJwt(state, jwtValue) {
            let jwt = commonLib.JWTtoJSon(jwtValue);
            state.formPreventivo.id_unita_operativa = jwt.id_unita_operativa;
            state.jwt = jwtValue;
        }
    },

    getters: {
        getField,
        axios_is_initialized: (state) => {
            return state.axios_is_initialized;
        },
        is_authenticated: state => state.is_authenticated,
        jwt: state => state.jwt,
        jwtPayload: state => {
            if (commonLib.isEmpty(state.jwt)) {
                return {};
            }
            return commonLib.estraiJsonPayloadDaJwt(state.jwt);
        },
        getArea: (state) => {
            return state.area;
        }
    },
    actions,

    // plugins: [vuexLocalStorage.plugin],

});

// store.commit("setUnauthendicated");

export default store;
