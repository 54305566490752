export default [

    // TODO: redirect a https://www.abyway.it/login
    {
        path: '/401',
		name: 'Unauthorized401',
        component: () => import('@/pages/Unauthorized401'),
    },
    {
        path: '/404',
		name: 'NotFound404',
        component: () => import('@/pages/NotFound404'),
    },
];
