import { getField, updateField } from 'vuex-map-fields';
import api from "@/libs/api";

export default {
	namespaced: true,
	state: () => {
		return {
            is_loading: true,
            have_errors: false,
            righe: [],
            colonne: []
		};
	},
	mutations: {
		updateField
	},
	getters: {
        getField
	},
	actions: {
        async fetchPraticheDefinite({state},{DalGiorno,AlGiorno,Compagnia}) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "estrazioni/getPraticheDefinite?DalGiorno="+DalGiorno+"&AlGiorno="+AlGiorno+"&Compagnia="+Compagnia;
            let oJSon = await api.getJSonFromController(url);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.righe = oJSon.data.rows;
                state.colonne = oJSon.data.columns;
                state.is_loading = true;
            }
        },
        async fetchPraticheCaricate({state},{dal_giorno,al_giorno,stato,compagnia}) {
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "estrazioni/getPraticheCaricate";

            let dati = {
                DalGiorno: dal_giorno,
                AlGiorno: al_giorno,
                Stato: stato,
                Compagnia: compagnia
            };

            let oJSon = await api.postJSonToController(url,dati);

            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.righe = oJSon.data.rows;
                state.colonne = oJSon.data.columns;
                state.is_loading = true;
            }
        },
        async fetchScadenzeContrattuali({state},{DalGiorno,AlGiorno,compagnia}) {
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "estrazioni/getScadenzeContrattuali";

            let dati = {
                DalGiorno: DalGiorno,
                AlGiorno: AlGiorno,
                Compagnia: compagnia
            };

            let oJSon = await api.postJSonToController(url,dati);

            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.righe = oJSon.data.rows;
                state.colonne = oJSon.data.columns;
                state.is_loading = true;
            }
        },
        async fetchScadenzeSemestrali({state},{DalGiorno,AlGiorno,compagnia}) {
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "estrazioni/getScadenzeSemestrali";

            let dati = {
                DalGiorno: DalGiorno,
                AlGiorno: AlGiorno,
                Compagnia: compagnia
            };

            let oJSon = await api.postJSonToController(url,dati);

            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.righe = oJSon.data.rows;
                state.colonne = oJSon.data.columns;
                state.is_loading = true;
            }
        },
        async fetchPortafoglioAttivo({state},data_estrazione) {
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "estrazioni/getPortafoglioAttivo";

            let dati = {
                DataEstrazione: data_estrazione
            };

            let oJSon = await api.postJSonToController(url,dati);

            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.righe = oJSon.data.rows;
                state.colonne = oJSon.data.columns;
                state.is_loading = true;
            }
        }
    /*    async fetchQuietanzeInScadenza({ state },{DalGiorno,AlGiorno}) {
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "estrazioni/getQuietanzeInScadenza?DalGiorno="+DalGiorno+"&AlGiorno="+AlGiorno;
            let oJSon = await api.getJSonFromController(url);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.righe = oJSon.data.rows;
                state.colonne = oJSon.data.columns;
                state.is_loading = true;
            }
        }*/
	}
};
