import { getField, updateField } from 'vuex-map-fields';

export default {
	namespaced: true,
	state: () => {
		return {
            child_route: "",
            RecordSelezionato: []
		};
	},
	mutations: {
		updateField,
	},
	getters: {
        getField,
	},
	actions: {
        async setChildRoute({state}, child_route) {
            state.child_route = child_route;
        }
	}
};
