<template>
    <q-btn
        :color="color"
        :class="`btn-fixed-width-${size}`"
        :size="size"
        :disable="disable"
        class="rounded-borders no-border q-ma-xs"
        :click="onclick">
        <q-list>
            <q-item>

                <q-item-section avatar
                    v-if="icon_align === 'left'">
                    <q-avatar :color="icon_color"
                        v-if="icon !== ''"
                        text-color="black" :icon="icon"
                        :size="size"
                    />
                </q-item-section>

                <q-item-section>
                    <q-item-label>{{label}}</q-item-label>
                </q-item-section>

                <q-item-section avatar
                    v-if="icon_align === 'right'"
                    class="text-right">
                    <q-avatar :color="icon_color"
                        v-if="icon !== ''"
                        text-color="black" :icon="icon"
                        :size="size"
                    />
                </q-item-section>
            </q-item>
        </q-list>

    </q-btn>
</template>

<style scoped>
    .btn-fixed-width-sm {
        width: 140px;
        height: 50px;
    }
    .btn-fixed-width-md {
        width: 210px;
        height: 70px;
    }
    .btn-fixed-width-xl {
        width: 320px;
        height: 140px;
    }
</style>

<script>
export default {
  name: 'QQButton',
  props: {
    icon: {
      type: String,
      required: false,
      default: ""
    },
    label: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    icon_color: {
      type: String,
      required: false,
      default: "orange-3"
    },
    icon_align: {
        type: String,
        required: false,
        default: "left"
    },
    size: {
      type: String,
      required: false,
      default: ""
    },
    onclick: {
      type: Function,
      required: false
    },
    disable: {
        type: Boolean,
        required: false,
        default: false
    }
  },
  computed: {

  }
}
</script>
