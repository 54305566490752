import { getField, updateField } from 'vuex-map-fields';
import api from "@/libs/api";

export default {
    namespaced: true,
    state: () => {
        return {
            is_loading: true,
            have_errors: false,
            guid_pratica: "",
            righe: [],
            colonne: [],
            pratica: {},
            contratto: {},
            quietanza: {},
            cliente: {},
            fattura: {},
            dati_economici: {},
            pagina_sorgente: ""
        };
    },
    mutations: {
        updateField
    },
    getters: {
        getField
    },
    actions: {
        async fetchConfigurazioneFatture({ state }, guid_pratica) {
            state.is_loading = false;
            state.guid_pratica = guid_pratica;
            let url = process.env.VUE_APP_API_URL + "fatture/ConfigurazioneFatture?guid_pratica=" + guid_pratica;
            let oJSon = await api.getJSonFromController(url);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.is_loading = true;

                return oJSon.data;
            }

            return false;
        },

        // TODO - Manca il flag intestazione AbyBroker che per default e` a TRUE
        async RegistraDatiFattura({ state }, { guidPratica, Pec, CodiceUnivocoDestinazione, EsentePec }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "fatture/RegistraDatiFattura";

            let dati = {
                guid_pratica: guidPratica,
                esente_obbligo_pec: EsentePec,
                codice_univoco_destinatario: CodiceUnivocoDestinazione,
                pec: Pec
            }

            let oJSon = await api.postJSonToController(url, dati);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.is_loading = true;

                return oJSon.data;
            }

            return false;
        },

        async ElencoFattureNonEmesse({ state }, { anno, nominativo, dalGiorno, alGiorno, guidPratica }) {
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL +
                "fatture/ElencoFattureNonEmesse?anno=" + anno + "&nominativo=" +
                nominativo + "&dal_giorno=" + dalGiorno + "&al_giorno=" + alGiorno +
                "&guid_pratica=" + guidPratica;
            let oJSon = await api.getJSonFromController(url);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.is_loading = true;
                state.righe = oJSon.data.rows;
                state.colonne = oJSon.data.columns;

            }


            state.is_loading = true;
        },

        async ElencoFattureEmesse({ state }, anno) {
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "fatture/ElencoFattureEmesse?anno=" + anno;
            let oJSon = await api.getJSonFromController(url);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.is_loading = true;
                state.righe = oJSon.data.rows;
                state.colonne = oJSon.data.columns;

            }

            state.is_loading = true;
        },

        async ElencoFattureErrore({ state }, { anno, nominativo, guidPratica }) {
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "fatture/ElencoFattureErrore?anno=" + anno +
                "&nominativo=" + nominativo + "&guid_pratica=" + guidPratica;
            let oJSon = await api.getJSonFromController(url);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.is_loading = true;
                state.righe = oJSon.data.rows;
                state.colonne = oJSon.data.columns;
            }

            state.is_loading = true;
        },

        async InformazioniGeneraliFattura({ state }, { id_pratica, id_contratto }) {
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "fatture/InformazioniGeneraliFattura?idpratica=" + id_pratica + "&idcontratto=" + id_contratto;
            let oJSon = await api.getJSonFromController(url);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.pratica = oJSon.data.pratica;
                state.contratto = oJSon.data.contratto;
                state.quietanza = oJSon.data.quietanza;
                state.cliente = oJSon.data.cliente;
                state.fattura = oJSon.data.fattura;
                state.dati_economici = oJSon.data.dati_economici;
            }

            state.is_loading = true;

            return oJSon.data;
        },

        async RegistraDatiClienteFattura({ state }, { guidPratica, id_contratto, dati_cliente }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "fatture/RegistraDatiClienteFattura";

            let dati = {
                guid_pratica: guidPratica,
                idcontratto: id_contratto,
                cliente: dati_cliente
            }

            let oJSon = await api.postJSonToController(url, dati);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            }

            state.is_loading = true;
        },

        async RegistraDatiContrattoFattura({ state }, { guidPratica, _contratto, _quietanza, _datiEconomici }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "fatture/RegistraDatiContrattoFattura";

            let dati = {
                guid_pratica: guidPratica,
                contratto: _contratto,
                quietanza: _quietanza,
                dati_economici: _datiEconomici
            }

            let oJSon = await api.postJSonToController(url, dati);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            }

            state.is_loading = true;

        },

        async GetRegistroMovimenti({ state }, id_fattura) {
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "fatture/GetRegistroMovimenti?idfattura=" + id_fattura;
            let oJSon = await api.getJSonFromController(url);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } //else {

            //}

            state.is_loading = true;

            return oJSon.data.elenco_movimenti;
        }
    }
}
