export default [
    {
        //TODO: in ambiente locale andare qui
        path: '/dev',
		name: 'DevelopHome',
        component: () => import('@/pages/DevelopHome'),
        meta: {
            // visibile solo in dev
            onlyForDev: true
        }
    },
];
