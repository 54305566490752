import { getField, updateField } from 'vuex-map-fields';
import api from "@/libs/api";

export default {
    namespaced: true,
    state: () => {
        return {
            is_loading: true,
            have_errors: false
        };
    },
    mutations: {
        updateField
    },
    getters: {
        getField
    },
    actions: {
        async isOtpIdentificazioneEnabled({ state }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "clienti/otp/is_otp_identificazione_enabled";
            let oJSon = await api.getJSonFromController(url);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.is_loading = true;

                return oJSon.data;
            }

            return false;
        },
        async isOtpFirmaDocumentiEnabled({ state }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "clienti/otp/is_otp_firmadocumenti_enabled";
            let oJSon = await api.getJSonFromController(url);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.is_loading = true;

                return oJSon.data;
            }

            return false;
        },

        async verificaFormaleCellulare({ state }, { numero_cellulare, guidCliente }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "clienti/otp/verificaFormaleCellulare?cellulare=" + numero_cellulare + "&guid_cliente=" + guidCliente;
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
                return null;
            }

            console.log("verificaFormaleCellulare:", oJSon.data);

            return oJSon.data;
        },

        async inviaOTPPerIdentificazione({ state }, { numero_cellulare, guidCliente }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "clienti/otp/inviaOTPPerIdentificazione?cellulare=" + numero_cellulare + "&guid_cliente=" + guidCliente;
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
                return null;
            }

            console.log("inviaOTPPerIdentificazione:", oJSon.data);

            return oJSon.data;
        },

        async verificaOTPPerIdentificazione({ state }, { numero_cellulare, guidCliente, codice_otp, rappresentante_legale, guidPreventivo }) {
            state.is_loading = false;
            console.log("codice_otp:", codice_otp);

            let url = process.env.VUE_APP_API_URL + "clienti/otp/verificaOTPPerIdentificazione?cellulare=" +
                numero_cellulare + "&guid_cliente=" + guidCliente + "&guid_preventivo=" + guidPreventivo +
                "&codice_otp=" + codice_otp +
                "&rappresentante_legale=" + rappresentante_legale;
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
                return null;
            }

            console.log("verificaOTPPerIdentificazione:", oJSon.data);

            return oJSon.data;
        },
        async verificaOTPPerfirma({ state }, { numero_cellulare, guidCliente, guidPreventivo, codice_otp }) {
            state.is_loading = false;
            console.log("codice_otp:", codice_otp);

            let url = process.env.VUE_APP_API_URL + "clienti/otp/verificaOTPPerfirma?cellulare=" + numero_cellulare + "&guid_cliente=" + guidCliente + "&guid_preventivo=" + guidPreventivo + "&codice_otp=" + codice_otp;
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
                return null;
            }

            console.log("verificaOTPPerfirma:", oJSon.data);

            return oJSon.data;
        },

        async infoCellulare({ state }, { numero_cellulare, guidCliente, guidPreventivo }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "clienti/otp/infoCellulare?cellulare=" + numero_cellulare + "&guid_cliente=" + guidCliente + "&guid_preventivo=" + guidPreventivo;
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
                return null;
            }

            return oJSon.data;
        },

        async isCellulareUsatoTroppo({ state }, { numero_cellulare, guidCliente }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "clienti/otp/infoCellulareTroppoUsato?cellulare=" + numero_cellulare + "&guid_cliente=" + guidCliente;
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
                return null;
            }

            return oJSon.data;
        },

        async sbloccaCellulareTroppoUsato({ state }, guidPreventivo) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "clienti/otp/sbloccaCellulareTroppoUsato?guidPreventivo=" + guidPreventivo;
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
                return null;
            }

            return oJSon.data;
        }
    }
}
