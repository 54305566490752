/**
 * client servizi geo
 */

import axios from 'axios';
// import store from '@/store';

//let url = "https://geo.swarm-staging.navert.cloud";
let url = process.env.VUE_APP_GEO_URL;

let apiGeo = {
    async fetchProvince() {
        let response = await axios
            .get(url + "/province")
            .catch(async (error) => await this.handleError(error));

        let province = response.data.data.province;
        return province;
    },

    async fetchComuni(provincia) {
        let response = await axios
            .get(url + "/comuni?sigla_provincia=" + provincia)
            .catch(async (error) => await this.handleError(error));
        let comuni = response.data.data;

        if (comuni === "Nessun comune trovato.") return [];

        //console.log("fetchComuni:",comuni);

        return comuni.filter(comune => {
            return comune.status !== "CESSATO";
        });
    },

    async fetchComuniForName(comune) {
        let response = await axios
            .get(url + "/comuni?nome=" + comune)
            .catch(async (error) => await this.handleError(error));
        let comuni = response.data.data;

        if (comuni === "Nessun comune trovato.") return [];

        //console.log("fetchComuniForName:",comuni);

        return comuni.filter(comune => {
            return comune.status !== "CESSATO";
        });
    },

    async fetchStati() {
        let response = await axios
            .get(url + "/stati")
            .catch(async (error) => await this.handleError(error));

        let stati = response.data.data;
        return stati;
    },

    async fetchTuttiComuni() {
        let response = await axios
            .get(url + "/comuni")
            .catch(async (error) => await this.handleError(error));

        let comuni = response.data.data;

        if (comuni === "Nessun comune trovato.") return [];

        //console.log("fetchTuttiComuni:",comuni);

        return comuni.filter(comune => {
            return comune.status !== "CESSATO";
        });
    },

    async provinciaFromComune(comune) {
        comune += "";
        switch (comune.toUpperCase()) {
            case "AGRIGENTO": return "AG";
            case "ALESSANDRIA": return "AL";
            case "ANCONA": return "AN";
            case "AOSTA": return "AO";
            case "AREZZO": return "AR";
            case "ASCOLI PICENO": return "AP";
            case "ASTI": return "AT";
            case "AVELLINO": return "AV";
            case "BARI": return "BA";
            case "BELLUNO": return "BL";
            case "BENEVENTO": return "BN";
            case "BERGAMO": return "BG";
            case "BIELLA": return "BI";
            case "BOLOGNA": return "BO";
            case "BOLZANO": return "BZ";
            case "BRESCIA": return "BS";
            case "BRINDISI": return "BR";
            case "CAGLIARI": return "CA";
            case "CALTANISSETTA": return "CL";
            case "CAMPOBASSO": return "CB";
            case "CARBONIA-IGLESIAS": return "CI";
            case "CASERTA": return "CE";
            case "CATANIA": return "CT";
            case "CATANZARO": return "CZ";
            case "CHIETI": return "CH";
            case "COMO": return "CO";
            case "COSENZA": return "CS";
            case "CREMONA": return "CR";
            case "CROTONE": return "KR";
            case "CUNEO": return "CN";
            case "ENNA": return "EN";
            case "FERMO": return "FM";
            case "FERRARA": return "FE";
            case "FIRENZE": return "FI";
            case "FOGGIA": return "FG";
            case "FORLI": return "FC";
            case "FROSINONE": return "FR";
            case "GENOVA": return "GE";
            case "GORIZIA": return "GO";
            case "GROSSETO": return "GR";
            case "IMPERIA": return "IM";
            case "ISERNIA": return "IS";
            case "LA SPEZIA": return "SP";
            case "L'AQUILA": return "AQ";
            case "LATINA": return "LT";
            case "LECCE": return "LE";
            case "LECCO": return "LC";
            case "LIVORNO": return "LI";
            case "LODI": return "LO";
            case "LUCCA": return "LU";
            case "MACERATA": return "MC";
            case "MANTOVA": return "MN";
            case "MASSA": return "MS";
            case "MATERA": return "MT";
            case "MEDIO CAMPIDANO": return "VS";
            case "MESSINA": return "ME";
            case "MILANO": return "MI";
            case "MODENA": return "MO";
            case "MONZA-BRIANZA": return "MB";
            case "NAPOLI": return "NA";
            case "NOVARA": return "NO";
            case "NUORO": return "NU";
            case "OGLIASTRA": return "OR";
            case "OLBIA-TEMPIO": return "OT";
            case "ORISTANO": return "OR";
            case "PADOVA": return "PD";
            case "PALERMO": return "PA";
            case "PARMA": return "PR";
            case "PAVIA": return "PV";
            case "PERUGIA": return "PG";
            case "PESARO": return "PU";
            case "PESCARA": return "PE";
            case "PIACENZA": return "PC";
            case "PISA": return "PI";
            case "PISTOIA": return "PT";
            case "PORDENONE": return "PN";
            case "POTENZA": return "PZ";
            case "PRATO": return "PO";
            case "RAGUSA": return "RG";
            case "RAVENNA": return "RA";
            case "REGGIO CALABRIA": return "RC";
            case "REGGIO EMILIA": return "RE";
            case "RIETI": return "RI";
            case "RIMINI": return "RN";
            case "ROMA": return "RM";
            case "ROVIGO": return "RO";
            case "SALERNO": return "SA";
            case "SASSARI": return "SS";
            case "SAVONA": return "";
            case "SIENA": return "";
            case "SIRACUSA": return "SR";
            case "SONDRIO": return "SO";
            case "TARANTO": return "TA";
            case "TERAMO": return "TE";
            case "TERNI": return "TR";
            case "TORINO": return "TO";
            case "TRAPANI": return "TP";
            case "TRENTO": return "TN";
            case "TREVISO": return "TV";
            case "TRIESTE": return "TS";
            case "UDINE": return "UD";
            case "VARESE": return "VA";
            case "VENEZIA": return "VE";
            case "VERBANIA": return "VB";
            case "VERCELLI": return "VC";
            case "VERONA": return "VR";
            case "VIBO VALENTIA": return "VV";
            case "VICENZA": return "VI";
            case "VITERBO": return "VT";
        }
    },

    async handleError(error) {
        // errore server
        if (error.response) {
            console.error(
                "[GEO] Errore server (status " + error.response.status + ")",
                error.response.data
            );
            return;
        }
        // errore client
        if (error.request) {
            console.error(
                "[GEO] Errore client",
                error.response.request
            );
            return;
        }
        // altri errori
        console.error('[GEO] Errore sconosciuto', error.message);
    },
};

export default apiGeo;
