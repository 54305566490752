import apiGeo from '@/libs/api-geo';

export default {
	namespaced: true,
	state: () => {
		return {
            comuni_is_initialized: false,
            comuni: []
		};
	},
	mutations: {
	},
	getters: {
        getComuni(state) {
            return state.comuni;
        }
	},
	actions: {
        async initComuni({state}) {
            if(state.comuni_is_initialized) return;

            let comuni_api = await apiGeo.fetchTuttiComuni();
            state.comuni = comuni_api;
            state.comuni_is_initialized = true;
        }
	}
};
