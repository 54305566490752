import { getField, updateField } from 'vuex-map-fields';
import api from "@/libs/api";

export default {
    namespaced: true,
    state: () => {
        return {
            child_route: "",
            righe: [],
            colonne: [],
            is_loading: true,
            formPratica: [],
            RecordSelezionato: [],
            dati_cliente: {},
            forza_taylormade: false,
            quietanza_da_rinnovare: {
                da_definire: false,
                data_incasso: "",
                decorrenza: "",
                fornitore: "",
                idquietanza: 0,
                modalita_incasso: "",
                quietanza_attiva: false,
                quietanza_interanno: false,
                scadenza: ""
            }
        };
    },
    mutations: {
        updateField,
    },
    getters: {
        getField,
    },
    actions: {

        async fetchContrattiInScadenza({ state }, { DalGiorno, AlGiorno }) {
            state.have_errors = false;
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "rinnovi/elenco_scadenza_contratti?dal_giorno=" + DalGiorno + "&al_giorno=" + AlGiorno;
            let oJSon = await api.getJSonFromController(url);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;

                return null;
            } else {
                state.is_loading = true;

                return oJSon.data.elenco;
            }
        },

        async fetchRegistraRinnoviDaSede({ state }, elenco_rinnovi) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "rinnovi/registra_rinnovi_da_sede";

            var elenco = {
                elenco_pratiche: elenco_rinnovi
            }

            await api.postJSonToController(url, elenco);

            state.is_loading = true;
        },

        async fetchContrattiInScadenzaUfficio({ state }, { idUnitaOperativa, isSede }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "rinnovi/elenco_scadenza_contratti_ufficio?id_unita_operativa=" + idUnitaOperativa + "&visualizza_tutti=" + isSede;

            var oJSon = await api.getJSonFromController(url);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;

                return null;
            } else {
                state.is_loading = true;

                return oJSon.data.elenco;
            }
        },

        async fetchRinnovaContrattoRcp({ state }, dati_contratto) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "rinnovi/rinnova_contratto_rcp";

            var dati = {
                contratto: dati_contratto
            }
            await api.postJSonToController(url, dati);

            state.is_loading = true;
        },

        async fetchRinnovaContrattoNoTacitoRinnovo({ state }, dati_contratto) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "rinnovi/rinnova_contratto_no_tacitorinnovo";

            var dati = {
                contratto: dati_contratto
            }
            await api.postJSonToController(url, dati);

            state.is_loading = true;
        },

        async fetchRinnovaContrattoAltriRami({ state }, dati_contratto) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "rinnovi/rinnova_contratto_altri_rami";

            var dati = {
                contratto: dati_contratto
            }
            await api.postJSonToController(url, dati);

            state.is_loading = true;
        },

        async fetchConfermaDisdettaRinnovoContratti({ state }, dati_contratto) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "rinnovi/conferma_disdetta_rinnovo_contratto";

            var dati = {
                contratto: dati_contratto
            }
            await api.postJSonToController(url, dati);

            state.is_loading = true;
        },

        async fetchConvertiInTaylormade({ state, rootState }, form_preventivo) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "rinnovi/trasforma_in_taylormade";

            var dati = {
                formPreventivo: form_preventivo
            }
            var oJSon = await api.postJSonToController(url, dati);

            state.is_loading = true;

            rootState.formPreventivo.preventivo.prodotti = oJSon.data.formPreventivo.preventivo.prodotti;
        },

        async definisciQuietanza({ state }, { id_quietanza, _decorrenza, _scadenza, dataIncasso, abilitaSimplo }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "rinnovi/definisci_quietanza";

            var dati = {
                IDQuietanza: id_quietanza,
                decorrenza: _decorrenza,
                scadenza: _scadenza,
                data_incasso: dataIncasso,
                abilita_simplo: abilitaSimplo
            }

            //console.log("dati quietanza:", dati);
            await api.postJSonToController(url, dati);

            state.is_loading = true;
        }

    }

}
