import api from '@/libs/api';
import commonLib from "@/libs/commonLib"
import sentry from "@/libs/sentry"

export default {
	fetchElencoPolizzeProfessionisti(context) {
        var url = process.env.VUE_APP_API_URL + 'professionisti';
        var oJSon = api.getJSonFromController(url);
		context.state.rows = oJSon.rows;
		context.state.columns = oJSon.columns;
	},

	fetchElencoPolizzePersona(context) {
        var url = process.env.VUE_APP_API_URL + 'persona';
        var oJSon = api.getJSonFromController(url);
		context.state.rows = oJSon.rows;
		context.state.columns = oJSon.columns;
	},

	fetchElencoPolizzeAziende(context) {
        var url = process.env.VUE_APP_API_URL + 'aziende';
        var oJSon = api.getJSonFromController(url);
		context.state.rows = oJSon.rows;
		context.state.columns = oJSon.columns;
	},

	fetchElencoPolizzeCauzioni(context) {
        var url = process.env.VUE_APP_API_URL + 'cauzioni';
        var oJSon = api.getJSonFromController(url);
		context.state.rows = oJSon.rows;
		context.state.columns = oJSon.columns;
    },

    setError(context, {title, message, callback = null} ) {
        context.state.error.title = title;
        context.state.error.message = message;

        if(commonLib.isCallable(callback)) {
            context.state.error.callback = callback;
        }

        context.state.has_error = true;
    },

    resetError(context) {
        context.state.has_error = false;
        context.state.error.title = "";
        context.state.error.message = "";
    },

    async setJwtAuth(context, jwt) {
        console.log("vuex.setJwtAuth",jwt);
        // await context.commit("setJwt",jwt);
        // await context.dispatch("authenticate",jwt);
    },

    setRegistroMovimenti(context,registro) {
        context.state.registro_movimenti = registro;
    },

    // async authenticate(context) {
    //     console.log("vuex.authenticate",context.getters.jwt);
    //     api.initAxiosWithJwt(context.getters.jwt);
    //     context.state.is_authenticated = true;
    // },

    async authenticate(context) {
        let jwt = localStorage.getItem("jwt");
        if(commonLib.isEmpty(jwt)) {
            return;
        }

        await context.commit("setJwt",jwt);
        await api.initAxiosWithJwt(jwt);
        await context.commit("setAuthendicated");

        sentry.setUser();
    },
};
