<template>
    <!-- <div id="app"> -->
    <router-view/>
    <!-- </div> -->
</template>

<script>
import apiGeo from "@/libs/api-geo";
import {mapGetters} from "vuex";
export default {

    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            dark_mode: "localStorage/dark_mode"
        })
    },
    watch: {
        dark_mode(newVal) {
            this.$q.dark.set(newVal);
        }
    },
    async mounted() {
        await apiGeo.fetchProvince();
        this.$q.dark.set(this.dark_mode);
        //console.log(province);
    }
}
</script>

