import commonLib from "@/libs/commonLib";

// ==================================================
// Procedure PRIVATE
// ==================================================
let parseRegola = function (regola) {
    let nomeRegola = regola.split("#")[0];
    let messaggioErrore = regola.split("#")[1] ?? "";

    return { nomeRegola, messaggioErrore };
};

let parseAttributo = function (attributo) {
    let regola = attributo.split(":")[0];
    let valori = attributo.split(":")[1] ?? "";

    let { nomeRegola, messaggioErrore } = parseRegola(regola);

    return { nomeRegola, messaggioErrore, valori };
};

let analizzaRequired = function (value) {
    var result = !commonLib.isEmpty(value);

    return result;
};

let analizzaMin = function (listaValori, value) {
    return parseFloat(value) >= parseInt(listaValori);
};

let analizzaMax = function (listaValori, value) {
    return parseFloat(value) < parseInt(listaValori);
};

let analizzaLength = function (listaValori, value) {
    // verifica se è stato impostato in minimo e un massimo
    if (commonLib.isObject(value)) return true;
    if (commonLib.isEmpty(value)) value = "";

    var len = parseInt(value.replace(" ", "_").length);
    if (listaValori.indexOf(",") !== -1) {
        let Minimo = parseInt(listaValori.split(",")[0]);
        let Massimo = parseInt(listaValori.split(",")[1]);

        return (len >= Minimo) && (len <= Massimo);
    } else {
        return len === parseInt(listaValori);
    }
};

let analizzaNumber = function (value) {
    return !isNaN(value);
};

let analizzaAlfanumerico = function (valueInput) {
    var letters = /^[0-9a-zA-Z]+$/;
    return valueInput.value.match(letters);
};

let isAnnoBisestile = function (Anno) {
    Anno = parseInt(Anno);
    if (Anno % 400 === 0) return true;

    if (((Anno % 4) === 0) && ((Anno % 100) !== 0)) return true;

    return false;
}

let analizzaDate = function (dateValue) {
    //dateValue = dateValue.trim();
    if (commonLib.isEmpty(dateValue)) return false;

    // la data deve essere di 10 caratteri
    if (dateValue.length != 10) return false;

    let elements = dateValue.split("/");
    if (elements.length != 3) return false;

    let GiorniPerMese = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Il controllo vale per anni bisestili
    if (elements[0] > GiorniPerMese[parseInt(elements[1])]) return false;

    // Controlla anni non bisestili
    if ((parseInt(elements[1]) == 2) && (!isAnnoBisestile(elements[2]))) {
        if (elements[0] > 28) return false;
    }

    return true;
}

let analizzaEmail = function (email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

let analizzaValidazione = function (regola, valori, valueSet) {
    switch (regola.toLowerCase()) {
        // ===============================================
        case "required":
            return analizzaRequired(valueSet);
        // ===============================================
        case "min":
            return analizzaMin(valori, valueSet);
        // ===============================================
        case "max":
            return analizzaMax(valori, valueSet);
        // ===============================================
        case "length":
            return analizzaLength(valori, valueSet);
        // ===============================================
        case "number":
            return analizzaNumber(valueSet);
        // ===============================================
        case "alphanumeric":
            return analizzaAlfanumerico(valueSet);
        // ===============================================
        case "date":
            return analizzaDate(valueSet);
        // ===============================================
        case "email":
            return analizzaEmail(valueSet);
        // ===============================================
    }

    return true;
};

/*
=============================================
Procedura PUBBLICA
Controlla che le regole impostate siano rispettate
PARAM: regoleValidazione {String} - Stringa che contiene tutte
            le regole da verificare. Ogni regola viene separata
            dal carattere |. Le regole accettate sono le seguenti:
            - required: Rende obbligatoria la compilazione del campo
            - required#Descrizione errore. Rende obbligatoria la
                        compilazione del campo e restituisce l'errore
                        da visualizzare
            - length: Definisce la lunghezza della frase o un valore
                      minimo e massimo in cui la lunghezza deve essere
                      compresa:
                      es. length:10 - La frase deve essere lunga 10 caratteri
                          length:3,15 - La frase deve avere un numero di
                                        caratteri compreso tra 3 e 15
            - length#Descrizione errore - Come sopra ma inserisce una
                        descrizione dell'errore da visualizzare
            - min: Definisce il numero minimo di caratteri o il valore minimo
                    es. min:5 su numero - Il numero deve essere minore di 5
            - min#Descrizione errore - Come sopra ma visualizza un messaggio
                    di errore personalizzato
            - number: Verifica se il valore inserito sias un numero
            - number#Descrizione errore - Come sopra ma visualizza un errore
                    personalizzato
PARAM: valueSet {string} - Identifica il valore inserito sul
            campo da controllare
RESTITUISCE: {Boolean} - True = Regole rispettate
                         False = Una o piu regole non sono rispettate
ESEMPIO:  "min#Non ci siamo proprio. Devi inserirne almeno 14 di caratteri!!!:14|required#Maremma porcello!!! Devi selezionare qualcosa"
================================================
*/
let verificaRegole = function (regoleValidazione, valueSet) {
    let statoField = true;
    let errorMessage = "";

    regoleValidazione.forEach(regola => {
        let { nomeRegola, messaggioErrore, valori } = parseAttributo(regola);

        let isValid = analizzaValidazione(nomeRegola,
            valori,
            valueSet);

        statoField = statoField && isValid;

        if (!isValid) {
            errorMessage = commonLib.isEmpty(messaggioErrore)
                ? "Il campo non risulta valorizzato correttamente"
                : messaggioErrore;

        }

    });

    statoField = !statoField;

    return { Messaggio: errorMessage, esito: statoField };
}

export default {
    parseRegola,
    parseAttributo,
    analizzaValidazione,
    verificaRegole,
    analizzaRequired,
    analizzaMin,
    analizzaMax,
    analizzaLength,
    analizzaNumber,
    analizzaDate,
    analizzaAlfanumerico,
    isAnnoBisestile
}
