export default [
    {
        path: '/',
        name: 'PiattaformaRami',
        component: () => import('@/pages/PiattaformaRami'),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/fatture',
                component: () => import('@/pages/PiattaformaRami/Fatture'),
                children: [
                    {
                        path: "/",
                        name: "Fatture",
                        component: () => import('@/pages/PiattaformaRami/Fatture/MenuGestioneFatture')
                    },
                    {
                        path: "DatiClientePerFattura",
                        name: "Fatture.DatiClientePerFattura",
                        component: () => import('@/pages/PiattaformaRami/Fatture/DatiClientePerFattura')
                    },
                    {
                        path: "TabellaFattureNonEmesse",
                        name: "Fatture.TabellaFattureNonEmesse",
                        component: () => import('@/pages/PiattaformaRami/Fatture/TabellaFattureNonEmesse')
                    },
                    {
                        path: "TabellaFattureEmesse",
                        name: "Fatture.TabellaFattureEmesse",
                        component: () => import('@/pages/PiattaformaRami/Fatture/TabellaFattureEmesse')
                    },
                    {
                        path: "TabellaFattureErrore",
                        name: "Fatture.TabellaFattureErrore",
                        component: () => import('@/pages/PiattaformaRami/Fatture/TabellaFattureErrore')
                    },
                    {
                        path: "SchedaFattura",
                        name: "Fatture.SchedaFattura",
                        component: () => import('@/pages/PiattaformaRami/Fatture/SchedaFattura')
                    },
                    {
                        path: "ModificaDatiCliente",
                        name: "Fatture.ModificaDatiCliente",
                        component: () => import('@/pages/PiattaformaRami/Fatture/ModificaDatiCliente')
                    },
                    {
                        path: "ModificaDatiContratto",
                        name: "Fatture.ModificaDatiContratto",
                        component: () => import('@/pages/PiattaformaRami/Fatture/ModificaDatiContratto')
                    },
                    {
                        path: "RegistroMovimenti",
                        name: "Fatture.RegistroMovimenti",
                        component: () => import('@/pages/PiattaformaRami/Fatture/RegistroMovimenti')
                    }
                ]
            },
            {
                path: '/preventivi',
                component: () => import('@/pages/PiattaformaRami/Preventivi'),
                children: [
                    {
                        path: "/",
                        name: "Preventivi",
                        component: () => import('@/pages/PiattaformaRami/Preventivi/TabellaPreventivi')
                    },
                    {
                        path: "MenuGestionePreventivi",
                        name: "Preventivi.MenuGestionePreventivi",
                        component: () => import('@/pages/PiattaformaRami/Preventivi/MenuGestionePreventivi')
                    },
                    {
                        path: "SelezioneArea",
                        name: "Preventivi.SelezioneArea",
                        component: () => import('@/pages/PiattaformaRami/Preventivi/SelezioneArea')
                    },
                    {
                        path: "FormPreventivo",
                        name: "Preventivi.FormPreventivo",
                        component: () => import('@/pages/PiattaformaRami/Preventivi/FormCalcolo/FormPreventivo')
                    },

                    {
                        path: "Deroga",
                        name: "Preventivi.Deroga",
                        component: () => import('@/pages/PiattaformaRami/Preventivi/FormCalcolo/Deroga')
                    },

                    {
                        path: "DownloadDocumenti",
                        name: "Preventivi.DownloadDocumenti",
                        component: () => import('@/pages/PiattaformaRami/Preventivi/FormAcquisto/DownloadDocumenti')
                    },
                    {
                        path: "GestioneDocumenti",
                        name: "Preventivi.GestioneDocumenti",
                        component: () => import('@/pages/PiattaformaRami/Preventivi/FormAcquisto/GestioneDocumenti')
                    },
                    {
                        path: "ImpostaDate",
                        name: "Preventivi.ImpostaDate",
                        component: () => import('@/pages/PiattaformaRami/Preventivi/FormAcquisto/ImpostaDate')
                    },
                    {
                        path: "FineAcquisto",
                        name: "Preventivi.FineAcquisto",
                        component: () => import('@/pages/PiattaformaRami/Preventivi/FormAcquisto/FineAcquisto')
                    },
                    {
                        path: "RegistroMovimenti",
                        name: "Preventivi.RegistroMovimenti",
                        component: () => import('@/pages/PiattaformaRami/Preventivi/FormAcquisto/RegistroMovimenti')
                    },
                    {
                        path: "Questionario",
                        name: "Preventivi.QuestionariAssuntivi.Questionario",
                        component: () => import('@/pages/PiattaformaRami/Preventivi/QuestionariAssuntivi/QuestionarioGenerico')
                    }
                ]
            },
            {
                path: '/quotazioni',
                component: () => import('@/pages/PiattaformaRami/Quotazioni'),
                children: [
                    {
                        path: "/",
                        name: "Quotazioni",
                        component: () => import('@/pages/PiattaformaRami/Quotazioni/TabellaQuotazioni')
                    },
                    {
                        path: "MenuGestioneQuotazioni",
                        name: "Quotazioni.MenuGestioneQuotazioni",
                        component: () => import('@/pages/PiattaformaRami/Quotazioni/MenuGestioneQuotazioni')
                    },
                    {
                        path: "VisualizzaQuestionarioAssuntivo",
                        name: "Quotazioni.VisualizzaQuestionarioAssuntivo",
                        component: () => import('@/pages/PiattaformaRami/Quotazioni/VisualizzaQuestionarioAssuntivo')
                    },
                    {
                        path: "RegistraAnnotazione",
                        name: "Quotazioni.RegistraAnnotazione",
                        component: () => import('@/pages/PiattaformaRami/Quotazioni/RegistraAnnotazione')
                    },
                    {
                        path: "RifiutaQuotazione",
                        name: "Quotazioni.RifiutaQuotazione",
                        component: () => import('@/pages/PiattaformaRami/Quotazioni/RifiutaQuotazione')
                    },
                    {
                        path: "DefinisciQuotazione",
                        name: "Quotazioni.DefinisciQuotazione",
                        component: () => import('@/pages/PiattaformaRami/Quotazioni/DefinisciQuotazione')
                    },
                    {
                        path: "ElencoMovimenti",
                        name: "Quotazioni.RegistroMovimentiPreventivo",
                        component: () => import('@/pages/PiattaformaRami/Quotazioni/RegistroMovimentiPreventivo')
                    },
                    {
                        path: "InviaSegnalazione",
                        name: "Quotazioni.InviaSegnalazione",
                        component: () => import('@/pages/PiattaformaRami/Quotazioni/InviaSegnalazione')
                    },
                    {
                        path: "ProblemiSegnalati",
                        name: "Quotazioni.ProblemiSegnalati",
                        component: () => import('@/pages/PiattaformaRami/Quotazioni/ProblemiSegnalati')
                    },
                    {
                        path: "AnnullaQuotazione",
                        name: "Quotazioni.AnnullaQuotazione",
                        component: () => import('@/pages/PiattaformaRami/Quotazioni/AnnullaQuotazione')
                    },
                    {
                        path: "AllegaDocumenti",
                        name: "Quotazioni.AllegaDocumenti",
                        component: () => import('@/pages/PiattaformaRami/Quotazioni/AllegaDocumenti')
                    },
                    {
                        path: "ElencoDocumenti",
                        name: "Quotazioni.ElencoDocumenti",
                        component: () => import('@/pages/PiattaformaRami/Quotazioni/ElencoDocumenti')
                    }
                ]
            },
            {
                path: '/clienti',
                component: () => import('@/pages/PiattaformaRami/Clienti'),
                children: [
                    {
                        path: "/",
                        name: "Clienti",
                        component: () => import('@/pages/PiattaformaRami/Clienti/MenuClienti')
                    },
                    {
                        path: "SchedaCliente",
                        name: "Clienti.SchedaCliente",
                        component: () => import('@/pages/PiattaformaRami/Clienti/SchedaCliente')
                    },
                    {
                        path: "CercaCliente",
                        name: "Clienti.CercaCliente",
                        component: () => import('@/pages/PiattaformaRami/Clienti/CercaCliente')
                    },
                    {
                        path: "CercaCellulare",
                        name: "Clienti.CercaCellulare",
                        component: () => import('@/pages/PiattaformaRami/Clienti/CercaCellulare')
                    }
                ]
            },
            {
                path: '/estrazioni',
                component: () => import('@/pages/PiattaformaRami/Estrazioni'),
                children: [
                    {
                        path: "/",
                        name: "Estrazioni",
                        component: () => import('@/pages/PiattaformaRami/Estrazioni/MenuEstrazioni')
                    },
                    {
                        path: "PraticheDefinite",
                        name: "Estrazioni.PraticheDefinite",
                        component: () => import('@/pages/PiattaformaRami/Estrazioni/PraticheDefinite')
                    },
                    {
                        path: "PraticheCaricate",
                        name: "Estrazioni.PraticheCaricate",
                        component: () => import('@/pages/PiattaformaRami/Estrazioni/PraticheCaricate')
                    },
                    {
                        path: "ScadenzeContrattuali",
                        name: "Estrazioni.ScadenzeContrattuali",
                        component: () => import('@/pages/PiattaformaRami/Estrazioni/ScadenzeContrattuali')
                    },
                    {
                        path: "ScadenzeInteranno",
                        name: "Estrazioni.ScadenzeInteranno",
                        component: () => import('@/pages/PiattaformaRami/Estrazioni/ScadenzeInteranno')
                    },
                    {
                        path: "PortafoglioAttivo",
                        name: "Estrazioni.PortafoglioAttivo",
                        component: () => import('@/pages/PiattaformaRami/Estrazioni/PortafoglioAttivo')
                    }
                ]
            },
            {
                path: '/simpli',
                component: () => import('@/pages/PiattaformaRami/Simpli'),
                children: [
                    {
                        path: "/",
                        name: "Simpli",
                        component: () => import('@/pages/PiattaformaRami/Simpli/MenuSimpli')
                    },
                    {
                        path: "SimpliDaCaricare",
                        name: "Simpli.SimpliDaCaricare",
                        component: () => import('@/pages/PiattaformaRami/Simpli/SimpliDaCaricare')
                    },
                    {
                        path: "SimpliDaValidare",
                        name: "Simpli.SimpliDaValidare",
                        component: () => import('@/pages/PiattaformaRami/Simpli/SimpliDaValidare')
                    },
                    {
                        path: "SimpliScartati",
                        name: "Simpli.SimpliScartati",
                        component: () => import('@/pages/PiattaformaRami/Simpli/SimpliScartati')
                    },
                    {
                        path: "SimpliValidati",
                        name: "Simpli.SimpliValidati",
                        component: () => import('@/pages/PiattaformaRami/Simpli/SimpliValidati')
                    },
                    {
                        path: "VisualizzaDatiSimplo",
                        name: "Simpli.VisualizzaDatiSimplo",
                        component: () => import('@/pages/PiattaformaRami/Simpli/VisualizzaDatiSimplo')
                    },
                    {
                        path: "VisualizzaSimploValidato",
                        name: "Simpli.VisualizzaSimploValidato",
                        component: () => import('@/pages/PiattaformaRami/Simpli/VisualizzaSimploValidato')
                    },
                    {
                        path: "UploadSimpli",
                        name: "Simpli.UploadSimpli",
                        component: () => import('@/pages/PiattaformaRami/Simpli/UploadSimpli')
                    }
                ]
            },
            {
                path: '/statistiche',
                component: () => import('@/pages/PiattaformaRami/Statistiche'),
                children: [
                    {
                        path: "/",
                        name: "Statistiche",
                        component: () => import('@/pages/PiattaformaRami/Statistiche/MenuStatistiche')
                    }
                ]
            },
            {
                path: '/rinnovi',
                component: () => import('@/pages/PiattaformaRami/Pratiche'),
                children: [
                    {
                        path: "RinnovaDaSede",
                        name: "Rinnovi.RinnovaDaSede",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/Rinnovi/RinnovaPraticheDaSede')
                    },
                    {
                        path: "RinnovaDaUfficio",
                        name: "Rinnovi.RinnovaDaUfficio",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/Rinnovi/RinnovaPraticheDaUfficio')
                    },
                    {
                        path: "AnalisiNecessita",
                        name: "Rinnovi.AnalisiNecessita",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/Rinnovi/AnalisiNecessita')
                    },
                    {
                        path: "AnalisiRischio",
                        name: "Rinnovi.AnalisiRischio",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/Rinnovi/AnalisiRischio')
                    },
                    {
                        path: "Proposta",
                        name: "Rinnovi.Proposta",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/Rinnovi/Proposta')
                    },
                    {
                        path: "DefinisciQuietanza",
                        name: "Rinnovi.DefinisciQuietanza",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/Rinnovi/DefinisciQuietanza')
                    }
                ]
            },
            {
                path: '/pratiche',
                component: () => import('@/pages/PiattaformaRami/Pratiche'),
                children: [
                    {
                        path: "/",
                        name: "Pratiche",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/MenuGenerale')
                    },
                    {
                        path: "ElencoPratiche",
                        name: "Pratiche.ElencoPratiche",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/TabellaPratiche')
                    },
                    {
                        path: "MenuGestionePratiche",
                        name: "Pratiche.MenuGestionePratiche",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/MenuGestionePratiche')
                    },
                    {
                        path: "SchedaPratica",
                        name: "Pratiche.SchedaPratica",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/SchedaDellaPratica')
                    },
                    {
                        path: "ControlloRichiestaEmissione",
                        name: "Pratiche.ControlloRichiestaEmissione",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/ControlloRichiestaEmissione')
                    },
                    {
                        path: "CambiaStatoPratica",
                        name: "Pratiche.CambiaStatoPratica",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/CambiaStatoPratica')
                    },
                    {
                        path: "SegnalaProblemi",
                        name: "Pratiche.SegnalaProblemi",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/SegnalaProblemi')
                    },
                    {
                        path: "SegnalaProblemiASede",
                        name: "Pratiche.SegnalaProblemiASede",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/SegnalaProblemiASede')
                    },
                    {
                        path: "ProblemiSegnalati",
                        name: "Pratiche.ProblemiSegnalati",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/ProblemiSegnalati')
                    },
                    {
                        path: "AnnullaPratica",
                        name: "Pratiche.AnnullaPratica",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/AnnullaPratica')
                    },
                    {
                        path: "DefinisciPratica",
                        name: "Pratiche.DefinisciPratica",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/DefinisciPratica')
                    },
                    {
                        path: "DocumentiDeiContratti",
                        name: "Pratiche.DocumentiDeiContratti",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/DocumentiDeiContratti')
                    },
                    {
                        path: "ElencoPagamenti",
                        name: "Pratiche.ElencoPagamenti",
                        component: () => import('@/pages/PiattaformaRami/Pratiche/ElencoPagamenti')
                    }
                ]
            }

        ]
    }
];
