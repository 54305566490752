import { getField, updateField } from 'vuex-map-fields';
import api from "@/libs/api";

export default {
    namespaced: true,
    state: () => {
        return {
            is_loading: true,
            have_errors: false,
            guid_pratica: "",
            idpratica: -1,
            idcontratto: -1,
            idquietanza: -1,
            prodotto: "",
            compagnia: "",
            decorrenza: "",
            scadenza: ""
        };
    },
    mutations: {
        updateField
    },
    getters: {
        getField
    },
    actions: {
        async fetchElencoSimpliDaCaricare({ state }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "simpli/elenco_simpli_da_caricare";
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;

            return oJSon.data;
        },

        async fetchElencoSimpliDaValidare({ state }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "simpli/elenco_simpli_da_validare";
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;

            return oJSon.data;
        },

        async fetchElencoSimpliValidati({ state }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "simpli/elenco_simpli_validati";
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;

            return oJSon.data;
        },

        setDatiPraticaPerSimplo({ state }, {
            guidPratica,
            idpratica,
            idcontratto,
            idquietanza,
            prodotto,
            compagnia,
            decorrenza,
            scadenza
        }) {
            state.idpratica = idpratica;
            state.idcontratto = idcontratto;
            state.idquietanza = idquietanza;
            state.guid_pratica = guidPratica;
            state.prodotto = prodotto;
            state.compagnia = compagnia;
            state.decorrenza = decorrenza;
            state.scadenza = scadenza;
        },

        async fetchUploadUrlSimpli({ rootState }, { IDPreventivo, Filename }) {
            rootState.is_loading = false;

            console.log(IDPreventivo, Filename);

            /*    let url = process.env.VUE_APP_API_URL + "documenti/getUploadUrlPreventivi?NumeroPreventivo=" + IDPreventivo + "&Filename=" + Filename;

                let path = await api.postJSonToController(url, InfoDocumento); */

            rootState.is_loading = true;

            return ""; // path.data.result;
        },

        async registra_simplo_su_pratica({ rootState }, { guid_pratica, id_quietanza, note }) {
            rootState.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "simpli/registra_simplo_su_pratica";
            var dati = {
                GuidPratica: guid_pratica,
                idquietanza: id_quietanza,
                note_upload: note
            };
            let oJSon = await api.postJSonToController(url, dati);

            return oJSon;
        },

        async rifiuta_simplo_su_pratica({ rootState }, { guid_pratica, id_quietanza, note }) {
            rootState.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "simpli/rifiuta_simplo_su_pratica";
            var dati = {
                GuidPratica: guid_pratica,
                idquietanza: id_quietanza,
                note_rifiuto: note
            };
            let oJSon = await api.postJSonToController(url, dati);

            return oJSon;
        },

        async valida_simplo_su_pratica({ rootState }, { guid_pratica, id_quietanza }) {
            rootState.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "simpli/valida_simplo_su_pratica";
            var dati = {
                GuidPratica: guid_pratica,
                idquietanza: id_quietanza
            };
            let oJSon = await api.postJSonToController(url, dati);

            return oJSon;
        },

        async fetchElencoSimpliScartati({ state }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "simpli/elenco_simpli_scartati";
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;

            return oJSon.data;
        },

        async fetchSimpliQuietanza({ state }, { guidPratica, idquietanza }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "simpli/elenco_simpli_su_quietanza?guid_pratica=" + guidPratica + "&idquietanza=" + idquietanza;
            var oJSon = await api.getJSonFromController(url);
            state.is_loading = true;

            return oJSon.data;
        }

    }
}
