import Vue from 'vue';

import '@/styles/quasar.scss';
import 'quasar/dist/quasar.ie.polyfills';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/mdi-v5/mdi-v5.css';
import Quasar from 'quasar/src/vue-plugin.js';import Dialog from 'quasar/src/plugins/Dialog.js';;

import italiano from 'quasar/lang/it';

Vue.use(Quasar, {
	config: {
		brand: {
			primary: '#1A4C69'
			// ...
		}
	},
	components: {
		/* not needed if importStrategy is not 'manual' */
	},
	directives: {
        /* not needed if importStrategy is not 'manual' */
	},
	plugins: {
        Dialog
    },
	lang: italiano
});
