export default [
    {
        path: '/box-informativo',
        name: 'BoxInformativo',
        // importa BoxInformativo/index.vue
        component: () => import('@/pages/BoxInformativo'),
    },
    {
        path: '/box-informativo2',
        name: 'BoxInformativo2',
        // importa BoxInformativo2/index.vue
        component: () => import('@/pages/BoxInformativo2'),
    },
];
