import { getField, updateField } from 'vuex-map-fields';
import api from "@/libs/api";
import commonLib from '../../libs/commonLib';

export default {
    namespaced: true,
    state: () => {
        return {
            righe: [],
            colonne: [],
            is_loading: false,
            have_errors: false,
            idquotazione: -1,    // Identifica la quotazione selezionata dalla lista

            // Questo troiaio di framework vuole la struttura inizializzata QUI
            // altrimenti segnala errori di variabili inesistenti come non dichiarate
            questionari_assuntivi: {
                rcp_tecnico: {
                    domande: []
                },
                multirischio_casa: {
                    domande: []
                },
                infortuni: {
                    domande: []
                },
                globale_fabbricato: {
                    domande: []
                },
                multirischio_commercio: {
                    domande: []
                },
                multirischio_artigiano: {
                    domande: []
                },
                tutela_reddito: {
                    domande: []
                },
                spese_veterinarie: {
                    domande: []
                }
            }
        };
    },
    mutations: {
        updateField,
    },
    getters: {
        getField,
    },
    actions: {
        async fetchElencoQuotazioni({ state }) {
            state.have_errors = false;
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "quotazioni/elenco_quotazioni";
            let oJSon = await api.getJSonFromController(url);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            }

            state.is_loading = true;

            state.righe = oJSon.data.rows;
            state.colonne = oJSon.data.columns;
        },

        async resetQuestionariAssuntivi({ state }) {
            state.questionari_assuntivi = {
                rcp_tecnico: {
                    domande: []
                },
                multirischio_casa: {
                    domande: []
                },
                infortuni: {
                    domande: []
                },
                multirischio_commercio: {
                    domande: []
                },
                multirischio_artigiano: {
                    domande: []
                },
                multirischio_albergo: {
                    domande: []
                },
                viaggi: {
                    domande: []
                },
                leasing: {
                    domande: []
                },
                fotovoltaico: {
                    domande: []
                },
                catastrofali: {
                    domande: []
                }
            };
        },

        async loadQuestionariAssuntivi({ state }, guid_preventivo) {
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL;
            url += "questionari-assuntivi/getByIdPreventivo?id_preventivo=";
            url += guid_preventivo;

            let response_questionari = await api.getJSonFromController(url);

            // Recupera la lista dei prodotti
            /*    var prodotti = rootState.formPreventivo.preventivo.prodotti;
                var prodotti_taylormade = prodotti.filter(prodotto => {
                    return prodotto.is_checked && prodotto.is_taylormade;
                });

                var result = {};

                console.log("prodotti recuperati al volo:", prodotti_taylormade);

                console.log("response_questionari:", response_questionari.data.questionari);

                var questionari = response_questionari.data.questionari;
                for (var i=0;i<questionari.length;i++) {
                    var questionario = questionari[i];
                    if (questionario[i])
                } */

            state.questionari_assuntivi = response_questionari.data.questionari;

            state.is_loading = true;
        },

        // Registra tutti i questionari in un unico json
        async saveQuestionariAssuntivi({ state }, id_preventivo) {
            state.is_loading = false;

            var url = process.env.VUE_APP_API_URL +
                "questionari-assuntivi/SaveByIdPreventivo?id_preventivo=" + id_preventivo;

            await api.postJSonToController(url, state.questionari_assuntivi);

            state.is_loading = true;
        },

        async saveQuestionarioAssuntivoSpecifico({ state }, { id_preventivo, id_prodotto }) {
            state.is_loading = false;
            var url = process.env.VUE_APP_API_URL + "quotazioni/crea";

            //console.log(url,id_preventivo,id_prodotto);

            await api.postJSonToController(url, { guid_preventivo: id_preventivo, id_prodotto: id_prodotto });

            state.is_loading = true;
        },

        async fetchElencoProblemiSuQuotazione({ state }, id_quotazione) {
            state.have_errors = false;
            state.is_loading = false;

            let url = process.env.VUE_APP_API_URL + "quotazioni/elenco_segnalazioni?idquotazione=" + id_quotazione;
            let oJSon = await api.getJSonFromController(url);
            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.is_loading = true;
            }

            return oJSon.data.problemi;
        },

        async fetchDatiQuotazione({ state }, idquotazione) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "quotazioni/getById?idquotazione=" + idquotazione;
            let oJSon = await api.getJSonFromController(url);

            if (oJSon.request.status !== 200) {
                state.have_errors = true;
            } else {
                state.is_loading = true;
            }

            state.is_loading = true;

            return oJSon.data.quotazione;
        },

        async RegistraAnnotazioneSuQuotazione({ state }, { guid_preventivo, note }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "quotazioni/aggiungiNota";
            let numero_preventivo = guid_preventivo;

            let dati = { guid_preventivo: numero_preventivo, annotazione: note };

            await api.postJSonToController(url, dati);

            state.is_loading = true;
        },

        async RifiutaQuotazione({ state }, { guid_preventivo, id_quotazione, motivo_rifiuto }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "quotazioni/rifiuta";
            let numero_preventivo = guid_preventivo;

            let dati = { guid_preventivo: numero_preventivo, idquotazione: id_quotazione, annotazione: motivo_rifiuto };

            await api.postJSonToController(url, dati);

            state.is_loading = true;
        },

        async MettiInLavorazione({ state }, { guid_preventivo, id_quotazione }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "quotazioni/mettiInLavorazione";
            let numero_preventivo = guid_preventivo;

            let dati = { guid_preventivo: numero_preventivo, idquotazione: id_quotazione };

            await api.postJSonToController(url, dati);

            state.is_loading = true;
        },

        async DefinisciQuotazione({ state }, { guid_preventivo, id_quotazione, _compagnia, _tariffa, _fornitore, _emittente, _settore, _premio, _premio_semestrale, _tipo_rateazione, _durata_anni }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "quotazioni/definisci";
            let numero_preventivo = guid_preventivo;

            let dati = {
                guid_preventivo: numero_preventivo,
                idquotazione: id_quotazione,
                compagnia: _compagnia,
                tariffa: _tariffa,
                fornitore: _fornitore,
                emittente: _emittente,
                settore: _settore,
                premio: _premio,
                premio_semestrale: _premio_semestrale,
                tipo_rateazione: _tipo_rateazione,
                durata_anni: _durata_anni
            };

            //console.log("dati:",dati);

            await api.postJSonToController(url, dati);

            state.is_loading = true;
        },

        async isQuotazione({ state, rootState }, guid_preventivo) {
            state.is_loading = false;

            if (commonLib.isEmpty(guid_preventivo)) {
                console.log("preventivo: ", rootState);
                return false;
            }

            let url = process.env.VUE_APP_API_URL + "quotazioni/isQuotazione?guid_preventivo=" + guid_preventivo;

            let oJSon = await api.getJSonFromController(url);

            if (oJSon.request.status !== 200) {
                state.have_errors = true;
                state.is_loading = true;
                return false;
            }

            state.is_loading = true;

            state.idquotazione = oJSon.data.idquotazione;

            return oJSon.data.isQuotazione;
        },

        async isQuotazioneAcquistabile({ state }, guid_preventivo) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "quotazioni/isQuotazioneAcquistabile?guid_preventivo=" + guid_preventivo;

            let oJSon = await api.getJSonFromController(url);

            if (oJSon.request.status !== 200) {
                state.have_errors = true;
                state.is_loading = true;
                return false;
            }

            state.is_loading = true;

            return oJSon.data.isQuotazioneAcquistabile;
        },

        async inviaSegnalazioneSuQuotazione({ state }, { guid_preventivo, id_quotazione, segnalazione }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "quotazioni/inviaSegnalazione";
            await api.postJSonToController(url, {
                "guid_preventivo": guid_preventivo,
                "idquotazione": id_quotazione,
                "note": segnalazione
            });

            state.is_loading = true;
        },

        async RisolviProblema({ state }, id_problema) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "quotazioni/risolvi_problema";

            let dati = {
                idproblema: id_problema
            };

            await api.postJSonToController(url, dati);

            state.is_loading = true;
        },

        async AnnullaQuotazione({ state }, { guidpreventivo, id_quotazione, motivo }) {
            state.is_loading = false;
            let url = process.env.VUE_APP_API_URL + "quotazioni/annulla";

            let dati = {
                guid_preventivo: guidpreventivo,
                idquotazione: id_quotazione,
                motivo_annullo: motivo
            };

            await api.postJSonToController(url, dati);

            state.is_loading = true;
        }

    }
};
