import * as Sentry from "@sentry/vue";
import auth from "@/libs/auth"

let sentry = {
    setUser() {
        try {
            let user = auth.getUser();
            let sentry_user = {
                id: user.id_unita_operativa+"",
                email: user.nome_persona_operativa,
                // username: user.nome_persona_operativa,
                // id_unita_operativa: user.id_unita_operativa+""
            };

            Sentry.setUser(sentry_user);
            console.log("[SENTRY] User set", sentry_user);
        } catch(error) {
            console.log("[SENTRY] setUser",error);
        }
    }
};

export default sentry;
