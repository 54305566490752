/**
 * La libreria gestisce tutte le operazioni a basso livello
 * per la comunicazione con il controller (BackEnd).
 */

import axios from "axios";
import store from "@/store";
import mock_questionari from "@/components/QuestionariAssuntivi/Professionisti/mock_questionario_avvocato.js";
import commonLib from "./commonLib";
// import commonLib from "./commonLib";

let api = {
    erroreDiAutenticazione: function () {
        let error = {
            title: "Errore di autenticazione",
            message: "Non sei autorizzato ad entrare",
            callback: () => {
                store.dispatch("resetError");
                window.location = process.env.VUE_APP_URL;
            },
        };

        store.dispatch("setError", error);
    },

    erroreDiRete: function () {
        let error = {
            title: "Errore di rete",
            message:
                "Si è verificato un errore di rete. Verifica di essere connesso ad internet.",
            callback: () => {
                store.dispatch("resetError");
            },
        };

        store.dispatch("setError", error);
    },

    erroreDiCodice: function () {
        let error = {
            title: "Errore di elaborazione dati sul server",
            message:
                "Si è verificato un errore durante l'elaborazione dei dati sul server. Contatta l'assistenza per una verifica.",
            callback: () => {
                store.dispatch("resetError");
            },
        };

        store.dispatch("setError", error);
    },

    catchConnectionError: async function (error) {
        // la richiesta è partita e il server ha risposto con errore
        if (error.response) {
            if (error.response.status === 401) {
                this.erroreDiAutenticazione();
                return null;
            }

            //if(error.response.status >= 500) {
            this.erroreDiCodice();
            return null;
            //}

            //console.log("errore di connessione nella risposta:", error);
            //return error.response;
        }

        // la richiesta è partita ma il server non ha risposto
        if (error.request) {
            this.erroreDiRete();
            return null;
        }

        // la richiesta non è partita
        this.erroreDiRete();
        return null;
    },

    /**
     * La funzione modifica l'header nella chiamata al controller
     * per inviare il token FWT al backend. Il token e` registrato
     * nello store nella variabile JWT.
     */
    initAxios: async function () {
        if (store.getters.axios_is_initialized) {
            return;
        }

        let jwt = store.getters.jwt;

        axios.defaults.headers.common = {
            Authorization: "Bearer " + jwt,
        };

        await store.commit("initAxios");
    },

    initAxiosWithJwt(jwt) {
        axios.defaults.headers.common = {
            Authorization: "Bearer " + jwt,
        };
    },

    analizzaCodiceStatoUrl: function (error) {
        if (
            typeof error.request === "undefined" ||
            error.request.status < 200
        ) {
            let errore = {
                data: [],
                response: {
                    status: 510,
                    statusText: "Network error",
                },
            };

            return errore;
        }

        switch (error.response.status) {
            case 401:
                //console.log("Errore 401 - Non autorizzato");
                //window.location = "/puppallero"
                break;

            default:
                return error;
            //break;
        }

        return error;
    },

    /* ==================================================================
        La funzione interroga il backend associato al framework per
        recuperare tutte le informazioni necessarie. Le informazioni
        restituite dipendono dal valore di PathArea. Il backend
        restituisce sempre un JSON che la funzione restituisce senza
        fare altre elaborazioni.
        PARAM: (integer) url - Identifica l'URL richiesto dal backend
                               per recuperare le informazioni richieste
        RESTITUISCE: (object) - JSON con i dati richiesti
       ================================================================== */
    getJSonFromController: async function (url) {
        let response = [];

        if (url == process.env.VUE_APP_API_URL + "datipolizza") {
            return [
                {
                    label: "Dati del Contraente",
                    domande: [
                        {
                            is_disabled: false,
                            label: "Nominativo",
                            name: "",
                            options: [],
                            placeholder: "Cognome Nome/Ragione Sociale",
                            type: "text",
                            validation: "required",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                        {
                            is_disabled: false,
                            label: "Indirizzo di residenza",
                            name: "",
                            options: [],
                            placeholder: "",
                            type: "text",
                            validation: "required",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                        {
                            is_disabled: false,
                            label: "Comune di residenza",
                            name: "",
                            options: [],
                            placeholder: "",
                            type: "text",
                            validation: "",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                        {
                            is_disabled: false,
                            label: "C.A.P. di residenza",
                            name: "",
                            options: [],
                            placeholder: "",
                            type: "text",
                            validation:
                                "number#Il valore deve essere numerico|length#Il numero deve essere di 5 cifre:5",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                        {
                            is_disabled: false,
                            label: "Provincia di residenza",
                            name: "",
                            options: [],
                            placeholder: "",
                            type: "text",
                            validation:
                                "required|length#La provncia deve essere di due caratteri:2",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                        {
                            is_disabled: false,
                            label: "Sesso",
                            name: "",
                            options: [
                                {
                                    is_visible_if: "",
                                    label: "Maschile",
                                    value: "Maschile",
                                },
                                {
                                    is_visible_if: "",
                                    label: "Femminile",
                                    value: "Femminile",
                                },
                                {
                                    is_visible_if: "",
                                    label: "Societa",
                                    value: "Sociela",
                                },
                            ],
                            placeholder: "",
                            type: "select",
                            validation: "",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                        {
                            is_disabled: false,
                            label: "Codice Fiscale",
                            name: "",
                            options: [],
                            placeholder: "",
                            type: "text",
                            validation: "",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                        {
                            is_disabled: false,
                            label: "Partita IVA",
                            name: "",
                            options: [],
                            placeholder: "",
                            type: "text",
                            validation: "",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                    ],
                },
                {
                    label: "Dati dell`Assicurato",
                    domande: [
                        {
                            is_disabled: false,
                            label: "Nominativo",
                            name: "",
                            options: [],
                            placeholder: "Cognome Nome/Ragione Sociale",
                            type: "text",
                            validation: "",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                        {
                            is_disabled: false,
                            label: "Indirizzo di residenza",
                            name: "",
                            options: [],
                            placeholder: "",
                            type: "text",
                            validation: "",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                        {
                            is_disabled: false,
                            label: "Comune di residenza",
                            name: "",
                            options: [],
                            placeholder: "",
                            type: "text",
                            validation: "",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                        {
                            is_disabled: false,
                            label: "C.A.P. di residenza",
                            name: "",
                            options: [],
                            placeholder: "",
                            type: "text",
                            validation: "",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                        {
                            is_disabled: false,
                            label: "Provincia di residenza",
                            name: "",
                            options: [],
                            placeholder: "",
                            type: "text",
                            validation: "",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                        {
                            is_disabled: false,
                            label: "Sesso",
                            name: "",
                            options: [
                                {
                                    is_visible_if: "",
                                    label: "Maschile",
                                    value: "Maschile",
                                },
                                {
                                    is_visible_if: "",
                                    label: "Femminile",
                                    value: "Femminile",
                                },
                                {
                                    is_visible_if: "",
                                    label: "Societa",
                                    value: "Sociela",
                                },
                            ],
                            placeholder: "",
                            type: "select",
                            validation: "",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                        {
                            is_disabled: false,
                            label: "Codice Fiscale",
                            name: "",
                            options: [],
                            placeholder: "",
                            type: "text",
                            validation: "",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                        {
                            is_disabled: false,
                            label: "Partita IVA",
                            name: "",
                            options: [],
                            placeholder: "",
                            type: "text",
                            validation: "",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                    ],
                },
                {
                    label: "Dati di Polizza",
                    domande: [
                        {
                            is_disabled: false,
                            label: "Data di decorrenza",
                            name: "",
                            options: [],
                            placeholder: "",
                            type: "date",
                            validation: "date",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                        {
                            is_disabled: false,
                            label: "Data di scadenza",
                            name: "",
                            options: [],
                            placeholder: "",
                            type: "date",
                            validation: "date",
                            value: "",
                            is_visible: true,
                            is_visible_if: "",
                        },
                    ],
                },
                {
                    label: "Privacy",
                    domande: [
                        {
                            is_disabled: false,
                            label: "Consenso al trattamento dei dati personali",
                            name: "",
                            options: [
                                {
                                    is_visible_if: "",
                                    label: "Esprimo il consenso​ al trattamento dei miei dati personali inclusi quelli considerati come categorie particolari di dati.",
                                    value: "TrattamentoDati",
                                },
                                {
                                    is_visible_if: "",
                                    label: "Esprimo il consenso alla comunicazion dei miei dati personali ad enti pubblici e società di natura privata per le finalità indicate nell’informativa",
                                    value: "ComunicazioneDati",
                                },
                                {
                                    is_visible_if: "",
                                    label: "Esprimo il consenso ​al trattamento delle categorie particolari dei miei dati personali così come indicati nell’informativa.",
                                    value: "DatiCategorie",
                                },
                            ],
                            placeholder: "",
                            type: "group_checkbox",
                            validation: "",
                            value: [],
                            is_visible: true,
                            is_visible_if: "",
                        },
                    ],
                },
            ];
        }

        response = await axios
            .get(url)
            .then((response) => {
                return response;
            })
            .catch(async (error) => {
                return await this.catchConnectionError(error);
                // return analizzaCodiceStatoUrl(error);
            });

        return response;
    },

    /* ==================================================================
        La funzione invia al backend associato al framework un JSON
        con i dati lavorati dal FrontEnd. Il backend
        restituisce un JSON che la funzione restituisce senza
        fare altre elaborazioni.
        PARAM: (string) url - Identifica l'url su cui fare il post dei dati
        PARAM: (object) oJSon - Identifica il JSon da restituire al BackEnd
        RESTITUISCE: (object) - JSON con i dati necessari alla successiva
                                elaborazione
       ================================================================== */
    postJSonToController: async function (url, oJSon) {
        var response = await axios
            .post(url, oJSon)
            .then((response) => {
                return response;
            })
            .catch(async (error) => {
                return await this.catchConnectionError(error);
            });

        return response;
    },

    fetchQuestionariAssuntivi: async function (id_preventivo, mock = false) {
        if (mock) {
            await commonLib.sleep(300);
            let questionari = mock_questionari;
            return questionari;
        }
        //console.log(mock);

        let url = process.env.VUE_APP_API_URL;
        url += "questionari-assuntivi/getByIdPreventivo?id_preventivo=";
        url += id_preventivo;

        let response_questionari = await this.getJSonFromController(url);

        //console.log("Questionario completo = ",response_questionari);
        //console.log("ID Questionario = ",response_questionari.data.id_preventivo);

        return response_questionari.data.questionari;
    },
};

export default api;
